//libs
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import AlectifyTable from "components/shared/table";

//internal
import { ITasksTableProps } from "./TasksTable.interface";

import { fetchTasks } from "redux/components/tasks/sources";

import { PM_STATUS } from "enums";
import { PAGINATION } from "constants/index";
import { getSubProjectDetails } from "redux/components/sub-project/sources";
import { IRootState } from "redux/rootReducer";
import useTasksColumn from "./effects/useTasksColumns";
import { ITask, actions } from "redux/components/tasks";
import { actions as pmExternalActions } from "redux/components/pm-external";
import AlectifyText from "static/texts.json";
import { useForm } from "antd/es/form/Form";
import { lowerCase, truncate } from "lodash";
import AlectifyModal from "../modal";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import { MODAL_NAMES } from "constants/modal.constants";
import PmExternalTaskCompleteForm from "../pm-external-table/PmExternalTaskCompleteForm";
import { setUploadDocuments } from "redux/components/common/sources";
import {
  deletePMExternal,
  updatePrevMaintenanceStatus,
} from "services/pm-external/pm-external.service";
import { getPmsCounts } from "redux/components/pm-internal/sources";
import ConfirmationModal from "../confirmation/Confirmation";
import { WarningIcon } from "components/icons";
import { MESSAGES } from "constants/messages";
import { notification } from "antd";
import { message } from "antd/lib";
import { PM_WARNING_MESSAGES } from "constants/warning-messages.constant";
import { sumValues, updateComponentByNotfication } from "utils/helpers";
import { IPmExternal, PM_TYPES } from "redux/components/pm-external";
import { getPmExternal } from "redux/components/pm-external/sources";
import ReviewConfirmationModal from "../confirmation/ReviewConfirm";
import { cancelPMApiRequests } from "utils/client";
import { SERVICE_UNIQUE_ID } from "utils/constants";
import { useLocation } from "react-router-dom";
import DrawWorkOrderSparePart from "../work-order-spare-parts/DrawWorkOrderSparePart";
import WorkOrderCreateEdit from "pages/work-order/WorkOrderCreateEdit";

const TasksTable: React.FC<ITasksTableProps> = (props: ITasksTableProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { tableFilters } = useSelector(({ tableFilter }) => tableFilter);

  const [optionsPersisted, setPersistedOptions] = useState<IPagination | null>(
    null,
  );

  const [recordUpdate, setRecordUpdate] = useState<number>(0);
  const [tableLoader, setTableLoader] = useState<boolean>(false);
  const { common, tasks, pmExternal, auth } = useSelector(
    (state: IRootState) => state,
  );

  const activeMasterProject = common.activeMasterProject?.id;
  const [FormInstance] = useForm();
  const [SparePartForm] = useForm();
  const [submitReviewForm] = useForm();

  useEffect(() => {
    getTasks({
      ...optionsPersisted,
    } as any);
  }, [
    common.showMyWorkOrders,
    common.activeMasterProject?.id,
    common?.activeSubProject?.id,
    props.type,
    tableFilters,
    recordUpdate,
  ]);

  const triggerGetTask = () => {
    setRecordUpdate((prevCount) => prevCount + 1);
  };

  const getStatus = (argValues: any) => {
    if (
      argValues.statusShifterButtons === "COMPLETED" ||
      argValues.statusShifterButtons === "SKIPPED"
    ) {
      return argValues.statusShifterButtons;
    }

    if (!argValues.status) {
      return argValues.statusShifterButtons;
    } else {
      return argValues.status;
    }
  };

  const getTaskCategory = (argValues: any) => {
    const { orderTypeTab, taskCategory } = argValues;

    if (orderTypeTab === "tasks") {
      return undefined;
    }

    if (
      orderTypeTab === "pm-external" &&
      taskCategory !== "PREVENTIVE_MAINTENANCE" &&
      taskCategory !== "CORRECTIVE_MAINTENANCE"
    ) {
      return undefined;
    }

    if (orderTypeTab === "all") {
      return taskCategory || undefined;
    }

    return taskCategory || undefined;
  };

  const getOrderParams = (argValues: any) => {
    if (!argValues || Object.keys(argValues).length === 0) {
      return {};
    }

    return {
      orderField:
        argValues?.createdAt?.orderField || argValues?.dueDate?.orderField,
      orderBy: argValues?.createdAt?.orderBy || argValues?.dueDate?.orderBy,
    };
  };

  const getTasks = (
    options?: IPagination,
    requestId?: string | null,
    editArg?: any,
  ) => {
    const updatedOptions = { ...options };

    const assigneesArray =
      typeof updatedOptions?.assignees === "string"
        ? updatedOptions?.assignees.split(",")
        : [];
    delete updatedOptions?.assignees;

    let approvers: any[] = [];
    if (common.showMyWorkOrders) {
      approvers = [auth.user.id];
    }

    const {
      per_page,
      status,
      taskCategory,
      orderBy,
      orderField,
      ...restOptions
    } = updatedOptions || {};

    const params = {
      ...restOptions,
      page: restOptions.page || PAGINATION.DEFAULT_START_PAGE,
      limit: per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      taskCategory: getTaskCategory(editArg ? editArg : tableFilters),
      status: getStatus(editArg ? editArg : tableFilters),
      assignees: assigneesArray,
      approvers,
      ...(props.defaultParams ?? {}),
      ...getOrderParams(editArg ? editArg : tableFilters),
    };
    setPersistedOptions(params as any);

    if (
      tableFilters.orderTypeTab === "tasks" ||
      location.search.includes("tasks")
    ) {
      cancelPMApiRequests(
        `${SERVICE_UNIQUE_ID.UNIQUE_FETCH_TASKS}${requestId && requestId}`,
      );
      dispatch(
        fetchTasks(
          activeMasterProject || props.masterProjectId,
          props.subProjectId,
          params,
          PM_TYPES.TASK,
          requestId,
        ),
      );
    } else if (
      tableFilters.orderTypeTab === "pm-external" ||
      location.search.includes("pm-external")
    ) {
      cancelPMApiRequests(SERVICE_UNIQUE_ID.UNIQUE_FETCH_PM_EXTERNAL);
      dispatch(
        getPmExternal(
          common.activeMasterProject?.id || props.masterProjectId,
          props.subProjectId,
          params,
        ),
      );
    } else if (
      tableFilters.orderTypeTab === "all" ||
      location.search.includes("all")
    ) {
      cancelPMApiRequests(
        `${SERVICE_UNIQUE_ID.UNIQUE_FETCH_TASKS}${requestId && requestId}`,
      );
      dispatch(
        fetchTasks(
          activeMasterProject || props.masterProjectId,
          props.subProjectId,
          params,
          lowerCase(PM_TYPES.ALL),
          requestId,
        ),
      );
    }

    if (props.triggerCountsApi) {
      dispatch(
        getPmsCounts(
          activeMasterProject || props.masterProjectId,
          props.subProjectId,
          props.defaultParams ?? {},
        ),
      );
    }

    if (props?.masterProjectId !== "all") {
      dispatch(
        getSubProjectDetails(props?.masterProjectId, props?.subProjectId),
      );
    }
  };

  const openCreationDrawer = (
    _: any,
    isEditing: boolean = false,
    editType?: string,
    task?: ITask,
    isCopying: boolean = false,
  ) => {
    openCreationModal(isEditing, editType, task, isCopying);
  };

  const openCreationModal = (
    isEditing: boolean = false,
    editType?: string,
    task?: ITask,
    isCopying: boolean = false,
  ) => {
    FormInstance.resetFields();
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
      className: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
      title: isCopying
        ? `Copying ${truncate(task?.workTitle, {
            length: 50,
            omission: "...",
          })}`
        : isEditing
        ? `Update ${truncate(task?.workTitle, {
            length: 50,
            omission: "...",
          })}`
        : AlectifyText.CREATE_WORK_ORDER,
      width: 800,
      style: { top: 30 },
      okText: "Submit",
      destroyOnClose: true,
      onOk: FormInstance.submit,
      onCancel: () => {
        FormInstance.resetFields();
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
        });
      },
      children: (
        <WorkOrderCreateEdit
          FormInstance={FormInstance}
          editing={isEditing}
          editType={editType || ""}
          taskId={task?.id || ""}
          subProjectId={
            props.subProjectId === "all"
              ? task?.subProject.id
              : props.subProjectId || ""
          }
          masterProjectId={
            props.masterProjectId === "all"
              ? task?.project.id
              : props.masterProjectId || task?.project?.id
          }
          isTask={tableFilters.orderTypeTab === "tasks"}
          copying={isCopying}
          callbackEffects={(optionsPersisted, key, editArg) =>
            isEditing ? getTasks(optionsPersisted, key, editArg) : null
          }
          type={props.type || ""}
        />
      ),
    });
  };
  const updateStatus = async (
    pmExternal: IPmExternal | ITask,
    status: string,
    date?: Date,
    estimatedHours?: string,
    estimatedCost?: string,
    commentId?: string,
  ) => {
    if (tableFilters.orderTypeTab === "tasks") {
      dispatch(actions.showTasksLoader(true));
    } else {
      dispatch(pmExternalActions.setPMExterLoader(true));
    }
    try {
      const response = await updatePrevMaintenanceStatus(
        pmExternal?.id,
        status,
        {
          date: date ? date : new Date(),
          estimatedHours,
          estimatedCost,
          commentId: commentId || undefined,
        },
      );
      if (response?.status) {
        triggerGetTask();
        if (status === PM_STATUS.SKIPPED) {
          openSuccessMessageModal(AlectifyText.ORDER_SKIPPED);
        }
        if (status === PM_STATUS.COMPLETED) {
          openSuccessMessageModal(AlectifyText.ORDER_CLOSED);
        }
        if (tableFilters.orderTypeTab === "tasks") {
          dispatch(actions.showTasksLoader(false));
        } else {
          dispatch(pmExternalActions.setPMExterLoader(false));
        }
      }
    } catch (ex) {
      if (tableFilters.orderTypeTab === "tasks") {
        dispatch(actions.showTasksLoader(false));
      } else {
        dispatch(pmExternalActions.setPMExterLoader(false));
      }
      console.log(ex);
    } finally {
      dispatch(pmExternalActions.setPMExterLoader(false));
    }
  };

  const onUpdateStatus = (
    pmExternal: ITask | IPmExternal,
    status: string,
    date?: Date,
    estimatedHours?: string,
    estimatedCost?: string,
    commentId?: string,
  ) => {
    const message =
      PM_WARNING_MESSAGES[status] || MESSAGES.PM_EXTERNAL.COMMON_WARNING;

    return new Promise((resolve, reject) => {
      const update = async () => {
        if (tableFilters.orderTypeTab === "tasks") {
          dispatch(actions.showTasksLoader(true));
        } else {
          dispatch(pmExternalActions.setPMExterLoader(true));
        }
        try {
          await updateStatus(
            pmExternal,
            status,
            date,
            estimatedHours,
            estimatedCost,
            commentId,
          );
          if (tableFilters.orderTypeTab === "tasks") {
            dispatch(actions.showTasksLoader(false));
          } else {
            dispatch(pmExternalActions.setPMExterLoader(false));
          }
          ModalServiceInstance.close(AlectifyModal, {
            name: MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
          });
          submitReviewForm.resetFields();
          dispatch(setUploadDocuments([]));
          resolve(true);
        } catch (error) {
          if (tableFilters.orderTypeTab === "tasks") {
            dispatch(actions.showTasksLoader(false));
          } else {
            dispatch(pmExternalActions.setPMExterLoader(false));
          }
          reject(error);
        } finally {
          dispatch(pmExternalActions.setPMExterLoader(false));
        }
      };

      if (status === "WAITING FOR REVIEW") {
        update();
      } else {
        openWarningModal(
          message,
          update,
          pmExternal,
          () => resolve(false),
          status,
          status === "SKIPPED" ? MESSAGES.PM_EXTERNAL.SKIP_NOTE : "",
        );
      }
    });
  };

  const openSubmitForReviewModal = (task: ITask) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
      title: "Submit for Review",
      width: 700,
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.PM_SUBMIT_FOR_REVIEW_MODAL,
        });
        submitReviewForm.resetFields();
        dispatch(setUploadDocuments([]));
      },
      onOk: submitReviewForm.submit,
      okText: "Submit",
      closeIcon: false,
      children: (
        <PmExternalTaskCompleteForm
          form={submitReviewForm}
          subProjectId={
            props.subProjectId === "all"
              ? task.subProject.id
              : props.subProjectId || ""
          }
          pmExternal={task}
          masterProjectId={
            props.masterProjectId === "all"
              ? task.project.id
              : props.masterProjectId || ""
          }
          fetchPmExternal={fetchTasks}
          updateStatus={onUpdateStatus}
          procedureSteps={task.procedure?.procedureSteps}
        />
      ),
    });
  };

  const openSuccessMessageModal = (text: string) => {
    message.success(text);
  };

  const removeTask = async (pmId: string) => {
    try {
      setTableLoader(true);

      const response = await deletePMExternal(pmId);
      message.success(MESSAGES.GENERAL_MESSAGES.DELETED);

      if (response.status) {
        triggerGetTask();
        setTableLoader(false);
      }
    } catch (ex: any) {
      setTableLoader(false);

      notification.error({
        message:
          ex?.response?.data?.message ||
          MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG,
      });
    }
  };

  // ============== Warning modal =============>>>

  const openWarningModal = (
    message: string,
    successCb: () => void,
    pmExternal?: ITask | IPmExternal,
    cancelCb?: () => void,
    status?: string,
    note?: string,
  ) => {
    if (status === "COMPLETED") {
      const onCancel = () =>
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.REVIEW_COMPLETED,
        });
      ModalServiceInstance.open(AlectifyModal, {
        name: MODAL_NAMES.REVIEW_COMPLETED,
        title: AlectifyText.REVIEW_COMPLETE,
        width: 700,
        okText: AlectifyText.REVIEW_COMPLETE,
        onOk: () => {
          successCb();
          onCancel();
        },
        onCancel,
        children: (
          <ReviewConfirmationModal
            pmExternal={pmExternal}
            icon={WarningIcon}
            message={message}
            onConfirm={() => {
              successCb && successCb();
              onCancel();
            }}
            onCancel={() => {
              cancelCb && cancelCb();
              onCancel();
            }}
          />
        ),
      });
    } else {
      const onCancel = () =>
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.PM_EXTERNAL_REMOVE_WARNING_MODAL,
        });
      ModalServiceInstance.open(AlectifyModal, {
        name: MODAL_NAMES.PM_EXTERNAL_REMOVE_WARNING_MODAL,
        title: AlectifyText.CONFIRMATION,
        footer: null,
        onCancel,
        children: (
          <ConfirmationModal
            pmExternal={pmExternal}
            icon={WarningIcon}
            message={message}
            cancelText={"Cancel"}
            okText={status === "SKIPPED" ? "Skip" : "Delete"}
            note={note}
            onConfirm={() => {
              successCb && successCb();
              onCancel();
            }}
            onCancel={() => {
              cancelCb && cancelCb();
              onCancel();
            }}
          />
        ),
      });
    }
  };

  const openRemoveWaringModal = (pmId: string) => {
    openWarningModal(MESSAGES.PM_EXTERNAL.REMOVER_WARNING, () =>
      removeTask(pmId),
    );
  };

  const openSpareParts = (record: ITask | IPmExternal) => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 500,
      name: MODAL_NAMES.WORK_ORDER_DRAW_FORM_MODAL,
      title: "Draw Spare Part",
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.WORK_ORDER_DRAW_FORM_MODAL,
        });
      },
      onOk: SparePartForm.submit,
      okText: "Submit",
      children: (
        <DrawWorkOrderSparePart
          getHistory={() => {}}
          workOrder={record as any}
          FormInstance={SparePartForm}
          // callback={() => }
        />
      ),
    });
  };

  const columns = useTasksColumn({
    ...props,
    fetchTasks: triggerGetTask,
    filter: tableFilters.status
      ? tableFilters.status
      : tableFilters.statusShifterButtons,
    optionsPersisted,
    showSelection: props.showSelection,
    onEdit: openCreationDrawer,
    updateStatus: onUpdateStatus,
    openSubmitForReviewModal,
    removeTask: openRemoveWaringModal,
    openSpareParts: openSpareParts,
  });

  updateComponentByNotfication(tableFilters.orderTypeTab as string, () => {
    getTasks({
      ...optionsPersisted,
      // masterProjectId: activeMasterProject,
    } as any);
  });

  return (
    <AlectifyTable
      enableFilters={{
        createdAt: true,
        dueDate: true,
        status: true,
        taskCategory: true,
      }}
      showSearch
      noIcontainsSearch
      columns={columns}
      scroll={props.scroll}
      dataSource={
        tableFilters.orderTypeTab === "tasks" ||
        tableFilters.orderTypeTab === "all"
          ? tasks.data
          : pmExternal.data
      }
      loading={
        tableFilters.orderTypeTab === "tasks" ||
        tableFilters.orderTypeTab === "all"
          ? tasks.fetching || tableLoader
          : pmExternal.fetching || tableLoader
      }
      total={
        tableFilters.orderTypeTab === "tasks" ||
        tableFilters.orderTypeTab === "all"
          ? tasks.meta.totalItems
          : pmExternal.meta.totalItems
      }
      className={
        pmExternal.activeWorkOrderCardSwitcher
          ? ""
          : "alectify-table-card-columns"
      }
      onDataFetch={getTasks}
      columnFilterAsString
      customSearchKey="search"
      filters={{
        key: "status",
        options: [
          {
            label: `Open (${
              tableFilters.orderTypeTab === "tasks"
                ? tasks.pendingCounts
                : tableFilters.orderTypeTab === "all"
                ? sumValues([tasks.pendingCounts, pmExternal.pendingCounts])
                : pmExternal.pendingCounts || 0
            })`,
            value: [PM_STATUS.PENDING, PM_STATUS.WAITING_FOR_REVIEW] as any,
          },
          {
            label: `${AlectifyText.CLOSED} (${
              tableFilters.orderTypeTab === "tasks"
                ? tasks.completedCounts
                : tableFilters.orderTypeTab === "all"
                ? sumValues([tasks.completedCounts, pmExternal.completedCounts])
                : pmExternal.completedCounts || 0
            })`,
            value: PM_STATUS.COMPLETED,
          },
          {
            label: `Skipped (${
              tableFilters.orderTypeTab === "tasks"
                ? tasks.skippedCounts
                : tableFilters.orderTypeTab === "all"
                ? sumValues([tasks.skippedCounts, pmExternal.skippedCounts])
                : pmExternal.skippedCounts || 0
            })`,
            value: PM_STATUS.SKIPPED,
          },
        ],
      }}
    />
  );
};

TasksTable.defaultProps = {
  allowCreation: true,
  defaultParams: {},
  triggerCountsApi: true,
  scroll: { x: 1320, y: "calc(100vh - 350px)" },
  showSelection: false,
};

export default memo(TasksTable);
