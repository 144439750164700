import { memo, useEffect } from "react";

import AlectifySelect from "components/shared/select";
import AlectifyText from "static/texts.json";
import { ISelectMasterProjectProps } from "./SelectMasterProject.interface";
import { isEmpty } from "lodash";
import { getMasterProject } from "redux/components/master-project/sources";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import CircleIcon from "components/icons/CircleIcon";
import { truncateString } from "utils/helpers";
import "./SelectMasterProject.style.scss";

const SelectMasterProject: React.FC<ISelectMasterProjectProps> = (
  props: ISelectMasterProjectProps,
) => {
  const dispatch = useDispatch();
  const masterProjects = useSelector(
    (state: IRootState) => state.MasterProject,
  );

  const getMasterProjects = async () => {
    dispatch(getMasterProject({ version: 2 }));
  };

  const getOptions = () => {
    let options: any[] = [];
    if (props.addAllFilter) {
      options = [
        {
          label: "All",
          value: "all",
        },
      ];
    }
    if (!isEmpty(masterProjects.data)) {
      options = [
        ...options,
        ...masterProjects.data.map((project) => ({
          label: (
            <div className="masterproject-option">
              <CircleIcon fill={project?.color} />
              <span>{truncateString(project.name, 25)}</span>
            </div>
          ),
          value: project.id,
          ...project,
        })),
      ];
    }
    return options;
  };

  useEffect(() => {
    if (isEmpty(masterProjects.data)) {
      getMasterProjects();
    }
  }, []);

  return (
    <AlectifySelect
      placeholder={AlectifyText.SELECT_SITE}
      name="masterProject"
      label={props.showLabel ? `${AlectifyText.SITE}` : ""}
      loading={false}
      rules={props.rules}
      onSelect={props.onSelect}
      disabled={props.disabled}
      onChange={props.onChange}
      options={getOptions()}
      className="custom-select"
      defaultValue={props.defaultValue}
    />
  );
};
SelectMasterProject.defaultProps = {
  showLabel: true,
};
export default memo(SelectMasterProject);
