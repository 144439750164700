export const MODAL_NAMES = {
  ADD_TEAM_MEMBER_MODAL: "add-team-member-modal",
  TASK_APPROVAL_MODAL: "task-status-approve-modal",
  PM_SUBMIT_FOR_REVIEW_MODAL: "pm-external-submit-for-review-modal",
  DOCUMENT_ATTACHMENTS: "document-attachment",
  USER_EQUIPMENT_ACCESS_MODAL: "USER_EQUIPMENT_ACCESS_MODAL",
  USER_PROFILE_MODAL: "user_profile_modal",
  SUCCESSFUL_MESSAGE_MODAL: "successful-message-modal",
  PROCEDURE_IMPORT_MODAL: "procedure-import-modal",
  CONTACT_US_MODAL: "contact-us-modal",
  PROCEDURE_REMOVE_CONFIRMATION_MODAL: "procedure-remove-confirmation-modal",
  PM_INTERNAL_CLOSE_PROCEDURE_WARNING_MODAL:
    "pm-internal-close-procedure-warning-modal",
  PM_EXTERNAL_REMOVE_WARNING_MODAL: "pm-external-remove-warning-modal",
  PROCEDURE_STEPS_FILE_REMOVE_MODAL:
    "procedure-steps-file-remove-warning-modal",
  DASHHBOARD_MASTERPROJECT_FILTER: "dashboard-master-project-filter",
  CREATE_COMPANY: "create-company",
  CREATE_BRANCH: "create-company",
  CREATE_BRANCH_ADMIN: "create-company",
  AUTH_MODAL_ADMIN: "auth-modal-admin",
  USER_TYPE_MODAL: "user-type-modal",
  PREVIEW_PDF_MODAL: "preview_pdf-Modal",
  UNDO_WARNING_MODAL: "undo-warning-modal",
  PROCEDURE_IMAGE_DELETE_CONFIRATION_MODAL:
    "procedure-image-delete-confirmation-modal",
  UPLOAD_ASSETS_CSV_WARNING_MODAL: "upload-assets-csv-modal",
  DASHBOARD_WORK_ORDERS_MODAL: "DASHBOARD_WORK_ORDERS_MODAL",
  PM_DOCUMENTS_DELETE_CONFIRMATION_MODAL: "pm-documents-confirmation-modal",
  ASSETS_DETAIL: "assets-detail",
  VIEW_IMAGE_MODAL: "view-image-modal",
  REVIEW_COMPLETED: "review-completed",
  DELETE_WARNING: "delete-warning",
  STEPS_WARNING_MODAL: "steps-warning-modal",
  SECURITY_MESSAGE_MODAL: "security-message-modal",
  EDIT_DOCUMENT_MODAL: "edit-document-modal",
  DELETE_DOCUMENT_MODAL: "delete-document-modal",
  RESTORE_DOCUMENT_MODAL: "restore-document-modal",
  SHOW_UPLOADED_DOCUMENTS_MODAL: "show-uploaded-documents-modal",
  WORK_ODERS_SPARE_PARTS_MODAL: "work-order-spare-parts-modal",
  WORK_ORDER_DRAW_FORM_MODAL: "work-order-draw-form-modal",
  SELECT_SPARE_PARTS_MODAL: "select-spare-parts-modal",
  TASK_TABLE_IMAGE_MODAL: "task-table-image-modal",
  SPARE_PARTS_CREATE_ORDER: "spare-parts-create-order",
  ADD_NEW_USER_MODAL: "add-new-user-modal",
  ADD_NEW_TEAMS_MODAL: "add-new-teams-modal",
  DELETE_USER_USER_MANAGEMENT: "delete-user-user-management",
  DELETE_ASSETS_MODAL: "delete-assets-modal",
  ALECTIFY_DESCRIPTION_MODAL: "alectify-description-modal",
  INCIDENT_REPORTS_DOCUMENTS: "incident-report-documents",
  DASHBOARD_SPARE_PARTS_MODAL: "dashboard-spare-parts-modal",
  INCIDENT_REMOVE_MODAL: "incident-remove-modal",
  SELECT_GROUP_MODAL: "select-group-modal",
  INCIDENT_STATUS_MODAL: "incident-status-modal",
  ROUND_DETAIL_MODAL: "round-detail-modal",
  INCIDENT_REPORT_EMAIL: "incident-report-email",
  WORK_ORDER_CREATE_MODAL: "work-order-create-modal",
  ASSET_NAME_MODAL: "show-assets-work-order",
  WORK_ORDER_MODAL: "work-order-modal",
  FILTER_TABLE_MODAL: "filter-table-modal",
};
