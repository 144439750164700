import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "./Routes.constants";
import { actions } from "redux/components/Auth";
import { IRootState } from "redux/rootReducer";
import { FEATURE_FLAGS } from "redux/components/Auth";
import { fetchUserProfile } from "services/user/user.services";
import { isExternalUser, isFeatureEnabled } from "utils/helpers";
import Login from "pages/login";
import MyWorkOrders from "pages/my-work-orders";
import PrivateRoute from "./PrivateRoutes";
import ResetPassword from "pages/reset-password";
import MasterProject from "pages/master-project/listing";
import Forgetpassword from "pages/forget-password";
import SubProjectDetail from "pages/sub-project";
import MasterProjectDetail from "pages/master-project/details";
import Calendar from "pages/calendar";
import WorkOrderPMDetails from "pages/my-work-orders/details/pm";
import InventoryManagement from "pages/invertory-management";
import Procedures from "pages/procedures";
import Contacts from "pages/contacts";
import Dashboard from "pages/dashboard";
import Timeline from "pages/timeline";
import Admin from "pages/admin";
import CompanyWideSpareParts from "pages/company-wide/spare-parts";
import CompanyWideCalendar from "pages/company-wide/calendar/CompanyWideCalendar";
import AllWorkOrders from "pages/all-work-orders";
import IotDevices from "pages/iot-devices";
import AllTimeline from "pages/all-timeline";
import Notifications from "pages/notifications";
import IconList from "pages/icon-gallery";
import Documents from "pages/documents";
import { saveToLocal } from "utils/cache";
import { setActiveMasterProject } from "redux/components/common/sources";
import AutoCreateProcedure from "pages/auto-create-procedure";
import { Button, Result } from "antd";
import IncidentReport from "pages/incident-report/IncidentReport";
import DataRound from "pages/data-round";
import DataRoundProjectDetails from "pages/data-round/dr-project-details";
import Reports from "pages/reports/Reports";

const RootRoute = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: IRootState) => state.auth);
  const location = useLocation();

  const syncUserProfile = async () => {
    try {
      const userProfileResponse = await fetchUserProfile();
      dispatch(actions.loginUserPartialUpdate(userProfileResponse));
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect hook that triggers a syncUserProfile function at an interval of 24 hours (86400000 milliseconds)
  // if the user object is not empty. This ensures that the user's profile is synchronized periodically
  // to keep it up to date. The interval is cleared when the component unmounts or when the user object changes.
  useEffect(() => {
    if (!isEmpty(user)) {
      const intervalCall = setInterval(() => {
        syncUserProfile();
      }, 86400000);
      return () => {
        clearInterval(intervalCall);
      };
    }
  }, [user]);

  useEffect(() => {
    if (new URLSearchParams(location.search).has("redirected")) {
      /* For project filter if user coming from email reqirection project filter should be reset. */
      dispatch(setActiveMasterProject(null));
      if (isEmpty(user)) {
        saveToLocal(
          {
            pathname: `${location.pathname}${location.search}`,
          },
          "redirectTo",
          true,
          false,
        );
      }
    }
  }, []);

  const devRoutes = process.env.NODE_ENV !== "production";

  return (
    // <Router>
    <Routes>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.FORGET_PASSWORD} element={<Forgetpassword />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={ROUTES.ROOT} element={<Navigate to={ROUTES.LOGIN} />} />

      {/* only for dev env too see icons */}
      {devRoutes && (
        <Route element={<IconList />} path={ROUTES.ICONS_GALLERY} />
      )}
      {/* only for dev env too see icons */}

      <Route element={<PrivateRoute />}>
        {!isExternalUser(user) ? (
          <>
            <Route element={<MasterProject />} path={ROUTES.PROJECT} />
            <Route
              element={<MasterProjectDetail />}
              path={`${ROUTES.MASTER_PROJECT_DETAILS}/:masterProjectId`}
            />
            <Route
              element={<SubProjectDetail />}
              path={`${ROUTES.SUB_PROJECT_DETAIL}`}
            />
            {isFeatureEnabled(FEATURE_FLAGS.SPAREPART) && (
              <Route
                element={<InventoryManagement />}
                path={`${ROUTES.INVENTORY}`}
              />
            )}
          </>
        ) : (
          <></>
        )}
        <Route element={<MyWorkOrders />} path={`${ROUTES.MY_ITEMS}`} />
        {isFeatureEnabled(FEATURE_FLAGS.MY_CALENDAR) && (
          <Route element={<Calendar />} path={`${ROUTES.CALENDAR}`} />
        )}
        {isFeatureEnabled(FEATURE_FLAGS.CONTACT) && (
          <Route element={<Contacts />} path={`${ROUTES.CONTACTS}`} />
        )}
        <Route
          element={<WorkOrderPMDetails />}
          path={`${ROUTES.MY_ITEMS}${ROUTES.PM_DETAILS}`}
        />
        {isFeatureEnabled(FEATURE_FLAGS.PROCEDURE_LIBRARY) && (
          <Route element={<Procedures />} path={`${ROUTES.PROCEDURES}`} />
        )}
        {isFeatureEnabled(FEATURE_FLAGS.DASHBOARD) && (
          <Route element={<Dashboard />} path={`${ROUTES.DASHBOARD}`} />
        )}
        {isFeatureEnabled(FEATURE_FLAGS.NOTIFICATION) && (
          <Route element={<Notifications />} path={`${ROUTES.NOTIFICATIONS}`} />
        )}

        <Route element={<Timeline />} path={`${ROUTES.TIMELINE}`} />
        <Route element={<AllTimeline />} path={`${ROUTES.ALL_TIME_LINE}`} />
        {user?.is_superuser && (
          <Route element={<Admin />} path={`${ROUTES.ADMIN}`} />
        )}
        {isFeatureEnabled(FEATURE_FLAGS.ALL_WORKORDER) && (
          <Route
            element={<AllWorkOrders />}
            path={`${ROUTES.ALL_WORK_ORDERS}`}
          />
        )}
        <Route
          element={<AutoCreateProcedure />}
          path={`${ROUTES.AUTO_CREATE_PROCEDURE_}`}
        />

        <Route
          element={<IncidentReport />}
          path={`${ROUTES.INCIDENT_REPORTS}`}
        />

        <Route element={<Documents />} path={`${ROUTES.ASSET_DOCUMENTS}`} />
        <Route element={<Documents />} path={`${ROUTES.SITE_DOCUMENTS}`} />

        <Route element={<IotDevices />} path={`${ROUTES.IOT_DEVICES}`} />
        {isFeatureEnabled(FEATURE_FLAGS.OPERATOR_ROUND) && (
          <>
            <Route element={<DataRound />} path={ROUTES.DATA_ROUND} />
            <Route
              element={<MasterProjectDetail />}
              path={`${ROUTES.DATA_ROUND}/:masterProjectId`}
            />
            <Route
              element={<DataRoundProjectDetails />}
              path={`${ROUTES.DATA_ROUND_DETAIL}`}
            />
          </>
        )}
        {isFeatureEnabled(FEATURE_FLAGS.COMPANYWIDE) && (
          <>
            <Route
              element={<CompanyWideCalendar />}
              path={`${ROUTES.COMPANY_WIDE.CALENDAR}`}
            />
            <Route
              element={<CompanyWideSpareParts />}
              path={`${ROUTES.COMPANY_WIDE.SPARE_PARTS}`}
            />
          </>
        )}
        <Route element={<Reports />} path={`${ROUTES.REPORTS}`} />
      </Route>
      <Route
        path="*"
        element={
          <Result
            status="404"
            title="Wrong Route / No Permission"
            subTitle="Oops! Wrong route or you don't have permission for this page"
            extra={
              <Link to="/">
                <Button type="primary">Back Home</Button>
              </Link>
            }
          />
        }
      />
    </Routes>
  );
};

export default RootRoute;
