import { Col, Typography, Row, DatePicker, Button, Spin } from "antd";
import { IWorkOrderReportsProps } from "./WorkOrderReports.interface";
// import AlectifySelect from "components/shared/select";
import { ICalendarEventState } from "pages/calendar/Calendar.interface";
import { IRootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { IPmCalendarEventResponse } from "services/calendar/calendar.interface";
import { fetchPmCalendarEvents } from "services/calendar/calendar.service";
import MonthlyCalendarTemplate from "pages/calendar/calendar-pdf/MonthlyCalendarTemplate";
import WeeklyCalendarTemplate from "pages/calendar/calendar-pdf/WeeklyCalendarTemplate";
// import { fetchTasksService } from "services/tasks/tasks.service";
// import { PAGINATION } from "constants/index";
// import { PM_STATUS } from "enums";
import { ITask } from "redux/components/tasks";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import TasksTable from "components/shared/tasks-table";
// import { isExternalUser } from "utils/helpers";
import { PM_TYPES } from "redux/components/pm-external";
// import AlectifyButton from "components/shared/button";
// import { isEmpty, truncate } from "lodash";

const WorkOrderReports: React.FC<IWorkOrderReportsProps> = (
  props: IWorkOrderReportsProps,
) => {
  const [selectedMonth, setSelectedMonth] = useState<dayjs.Dayjs | null>(null);
  const [selectedWeek, setSelectedWeek] = useState<dayjs.Dayjs | null>(null);
  const [calendarEventMonthly, setCalendarEventMonthly] =
    useState<ICalendarEventState>({ data: [], fetching: false });
  const [calendarEventWeekly, setCalendarEventWeekly] =
    useState<ICalendarEventState>({ data: [], fetching: false });
  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const {
    common,
    auth: { user },
  } = useSelector((state: IRootState) => state);

  const getCalendarEvent = async (selectedMonthStartDate: Dayjs | null) => {
    if (!selectedMonthStartDate) return;
    setSelectedMonth(selectedMonthStartDate);
    setCalendarEventMonthly({ fetching: true });
    try {
      const startDate = selectedMonthStartDate
        .clone()
        .startOf("week")
        .format("YYYY-MM-DD");
      const endDate = selectedMonthStartDate
        .clone()
        .endOf("month")
        .endOf("week")
        .format("YYYY-MM-DD");

      const projectId = common.activeMasterProject?.id ?? "all";
      const subProjectId = "all";
      const options =
        common.showCalendarMyWorkOrders ||
        common.showCalendarMyWorkOrders === undefined
          ? {}
          : { global: "true" };

      const pmEvents: IPmCalendarEventResponse = await fetchPmCalendarEvents(
        projectId,
        subProjectId,
        startDate,
        endDate,
        options,
      );
      setCalendarEventMonthly({ fetching: false, data: pmEvents.data });
    } catch (error) {
      console.error(error);
      setCalendarEventMonthly({ fetching: false });
    }
  };

  const getWeeklyCalendarEvent = async (
    selectedWeekStartDate: Dayjs | null,
  ) => {
    if (!selectedWeekStartDate) return;
    setSelectedWeek(selectedWeekStartDate);
    setCalendarEventWeekly({ fetching: true });
    try {
      const startDate = selectedWeekStartDate
        .clone()
        .startOf("week")
        .format("YYYY-MM-DD");
      const endDate = selectedWeekStartDate
        .clone()
        .endOf("week")
        .format("YYYY-MM-DD");

      const projectId = common.activeMasterProject?.id ?? "all";
      const subProjectId = "all";
      const options =
        common.showCalendarMyWorkOrders ||
        common.showCalendarMyWorkOrders === undefined
          ? {}
          : { global: "true" };

      const pmEvents: IPmCalendarEventResponse = await fetchPmCalendarEvents(
        projectId,
        subProjectId,
        startDate,
        endDate,
        options,
      );
      setCalendarEventWeekly({ fetching: false, data: pmEvents.data });
    } catch (error) {
      console.error(error);
      setCalendarEventWeekly({ fetching: false });
    }
  };

  /*   const fetchWorkOrders = async (search?: string) => {
    setLoader(true);
    try {
      const params = {
        page: PAGINATION.DEFAULT_START_PAGE,
        limit: PAGINATION.DEFAULT_PAGE_SIZE,
        status: [
          PM_STATUS.PENDING,
          PM_STATUS.WAITING_FOR_REVIEW,
          PM_STATUS.INPROGRESS,
        ],
        assignees: [user.id],
        approvers: [user.id],
        search,
      };
      const response = await fetchTasksService(
        common.activeMasterProject?.id || "all",
        "all",
        params,
        "all",
      );
      setLoader(false);
      setTasks(response.data);
    } catch (ex) {
      setLoader(false);
      console.log("err fetching work orders");
    }
  }; */

  const closeWorkOrderModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.WORK_ORDER_MODAL,
    });
  };
  const onWorkOrderSelect = (task: ITask) => {
    setSelectedTask(task);
    closeWorkOrderModal();
  };
  const openWorkOrderTable = () => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.WORK_ORDER_MODAL,
      width: 1300,
      footer: null,
      onCancel: closeWorkOrderModal,
      closable: true,
      destroyOnClose: true,
      title: "Select Work Order",
      children: (
        <TasksTable
          scroll={{ x: "max-content", y: "calc(100vh - 324px)" }}
          myItemsColumns
          subProjectId="all"
          masterProjectId="all"
          allowCreation={false}
          triggerCountsApi={false}
          defaultParams={{ teamMembers: [user?.id], createdById: user?.id }}
          type={PM_TYPES.ALL}
          onSelectWorkOrder={onWorkOrderSelect}
          myWorkdOrder
          showSelection
        />
      ),
    });
  };

  return (
    <>
      <Col span={24}>
        <Typography.Title level={4}>Work Orders</Typography.Title>
      </Col>
      <div className="alectify-work-order-reports-container">
        <Spin spinning={loader}>
          <Col span={24}>
            <Row
              justify={"start"}
              align={"middle"}
              className="mb-10"
              gutter={16}
            >
              <Col span={4}>
                <Typography.Text>Calendar: Monthly Schedule</Typography.Text>
              </Col>
              <Col span={4}>
                <DatePicker
                  picker="month"
                  onChange={(date) => getCalendarEvent(date)}
                  size="large"
                  className="alectify-work-order-reports-date-picker"
                />
              </Col>
              <Col span={4}>
                <MonthlyCalendarTemplate
                  startDate={
                    selectedMonth
                      ?.clone()
                      .startOf("week")
                      .format("YYYY-MM-DD") || ""
                  }
                  endDate={
                    selectedMonth
                      ?.clone()
                      .endOf("month")
                      .format("YYYY-MM-DD") || ""
                  }
                  calendarEvents={calendarEventMonthly}
                />
              </Col>
            </Row>
            <Row
              justify={"start"}
              align={"middle"}
              className="mb-10"
              gutter={16}
            >
              <Col span={4}>
                <Typography.Text>Calendar: Weekly Schedule</Typography.Text>
              </Col>
              <Col span={4}>
                <DatePicker
                  picker="week"
                  onChange={(date) => getWeeklyCalendarEvent(date)}
                  size="large"
                  className="alectify-work-order-reports-date-picker"
                />
              </Col>
              <Col span={4}>
                <WeeklyCalendarTemplate
                  startDate={
                    selectedWeek
                      ?.clone()
                      .startOf("week")
                      .format("YYYY-MM-DD") || ""
                  }
                  endDate={
                    selectedWeek?.clone().endOf("week").format("YYYY-MM-DD") ||
                    ""
                  }
                  calendarEvents={calendarEventWeekly}
                />
              </Col>
            </Row>
            {/*  <Row justify={"start"} align={"middle"} className="mb-10">
            <Col span={4}>
              <Typography.Text>Work Order Report</Typography.Text>
            </Col>
            <Col span={4}>
              <AlectifyButton
                text={
                  !isEmpty(selectedTask)
                    ? truncate(selectedTask.workTitle, {
                        omission: "...",
                        length: 30,
                    }) || ""
                    : "Select Work Order"
                }
                onClick={openWorkOrderTable}
                type="primary"
                className="alectify-primary-btn"
              />
            </Col>
            <Col span={4}>
              <Button type="link">Download PDF</Button>
            </Col>
          </Row> */}
          </Col>
        </Spin>
      </div>
    </>
  );
};

export default WorkOrderReports;
