import React, { useState, useEffect, memo } from "react";
import { Col, message, Row, Space, Spin } from "antd";
import { IAlectifyTableFiltersProps } from "./AlectifyTable.interface";
import FilterIcon from "components/icons/FilterIcon";
import { useDispatch, useSelector } from "react-redux";
import { setTableFilters } from "redux/components/table-filters/sources";
import AlectifySelect from "../select";
import AlectifyButton from "components/shared/button";
import AlectifyText from "static/texts.json";
import { MESSAGES } from "constants/messages";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";
import "./AlectifyTableFilter.scss";

const AlectifyTableFilters: React.FC<IAlectifyTableFiltersProps> = ({
  enableFilters = {
    createdAt: false,
    dueDate: false,
    taskCategory: false,
    status: false,
  },
}) => {
  const dispatch = useDispatch();
  const { tableFilters } = useSelector(({ tableFilter }) => tableFilter);
  const [isLoading, setIsloading] = useState<boolean>(false);

  const shouldHideStatusFilter =
    tableFilters.statusShifterButtons === "COMPLETED" ||
    tableFilters.statusShifterButtons === "SKIPPED";

  const [activeButtons, setActiveButtons] = useState<{
    dueDate: string | null;
    createdAt: string | null;
  }>({
    dueDate: null,
    createdAt: null,
  });

  const [filterCount, setFilterCount] = useState<any>(0);

  const [selectedFilters, setSelectedFilters] = useState<{
    dueDate: string | null;
    createdAt: string | null;
    statusShifterButtons: string | null;
    status: string | null;
    taskCategory: string | null;
    orderTypeTab: string | null;
  }>({
    dueDate: null,
    createdAt: null,
    statusShifterButtons: null,
    status: null,
    taskCategory: null,
    orderTypeTab: null,
  });

  useEffect(() => {
    ModalServiceInstance.updateModalProps(
      MODAL_NAMES.FILTER_TABLE_MODAL,
      tableFilterModalConfig,
    );
  }, [
    activeButtons,
    enableFilters,
    selectedFilters,
    tableFilters,
    filterCount,
    shouldHideStatusFilter,
    isLoading,
  ]);

  useEffect(() => {
    const countSelectedFilters = () => {
      let count = Object.entries(selectedFilters).filter(
        ([key, value]) =>
          key !== "statusShifterButtons" &&
          key !== "orderTypeTab" &&
          value !== null,
      ).length;

      if (
        !selectedFilters.status &&
        selectedFilters.statusShifterButtons &&
        ["COMPLETED", "SKIPPED"].includes(selectedFilters.statusShifterButtons)
      ) {
        count += 1;
      }

      if (
        selectedFilters.orderTypeTab === "tasks" &&
        !selectedFilters.status &&
        !selectedFilters.taskCategory &&
        selectedFilters.statusShifterButtons
      ) {
        count += 1;
      }

      return count;
    };

    setFilterCount(countSelectedFilters());
  }, [selectedFilters]);

  useEffect(() => {
    if (tableFilters) {
      setActiveButtons({
        dueDate: tableFilters.dueDate?.orderBy ?? null,
        createdAt: tableFilters.createdAt?.orderBy ?? null,
      });
      setSelectedFilters({
        dueDate: tableFilters.dueDate?.orderBy ?? null,
        createdAt: tableFilters.createdAt?.orderBy ?? null,
        statusShifterButtons: tableFilters.statusShifterButtons ?? null,
        status: tableFilters.status,
        taskCategory:
          tableFilters.orderTypeTab === "pm-external" &&
          tableFilters.taskCategory !== "PREVENTIVE_MAINTENANCE" &&
          tableFilters.taskCategory !== "CORRECTIVE_MAINTENANCE"
            ? null
            : tableFilters.taskCategory,
        orderTypeTab: tableFilters.orderTypeTab,
      });
    }
  }, [tableFilters]);

  const handleSubmit = () => {
    closeTableFilterModal();

    const filtersToApply: Record<string, any> = {
      dueDate: null,
      createdAt: null,
      status: selectedFilters.status,
      taskCategory: selectedFilters.taskCategory,
    };

    if (selectedFilters.dueDate) {
      filtersToApply.dueDate = {
        orderField: "dueDate",
        orderBy: selectedFilters.dueDate,
      };
    }

    if (selectedFilters.createdAt) {
      filtersToApply.createdAt = {
        orderField: "createdAt",
        orderBy: selectedFilters.createdAt,
      };
    }

    if (selectedFilters.status) {
      filtersToApply.status = selectedFilters.status;
    }

    if (selectedFilters.taskCategory) {
      filtersToApply.taskCategory = selectedFilters.taskCategory;
    }

    dispatch(setTableFilters(filtersToApply));
  };

  const handleCancel = () => {
    closeTableFilterModal();
    setActiveButtons({
      dueDate: tableFilters.dueDate?.orderBy ?? null,
      createdAt: tableFilters.createdAt?.orderBy ?? null,
    });
  };

  const handleIndividualReset = (field: "dueDate" | "createdAt" | "status") => {
    setSelectedFilters((prev) => ({
      ...prev,
      [field]: null,
    }));
    setActiveButtons((prev) => ({
      ...prev,
      [field]: null,
    }));
  };

  const handleResetAll = async () => {
    setIsloading(true);

    await dispatch(
      setTableFilters({
        createdAt: null,
        dueDate: null,
        status: null,
        taskCategory: null,
        statusShifterButtons: tableFilters.statusShifterButtons,
      }),
    );
    setSelectedFilters({
      dueDate: null,
      createdAt: null,
      status: null,
      taskCategory: null,
      statusShifterButtons: tableFilters.statusShifterButtons,
      orderTypeTab: tableFilters.orderTypeTab,
    });

    setActiveButtons({
      dueDate: null,
      createdAt: null,
    });

    message.success(MESSAGES.GENERAL_MESSAGES.RESET_FILTERS);

    setIsloading(false);
    closeTableFilterModal();
  };

  const clickFilter = (orderField: string, orderBy: string | null) => {
    const otherField = orderField === "dueDate" ? "createdAt" : "dueDate";

    setActiveButtons((prev) => ({
      ...prev,
      [orderField]: orderBy === "None" ? null : orderBy,
      [otherField]: null,
    }));

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [orderField]: orderBy === "None" ? null : orderBy,
      [otherField]: null,
    }));
  };

  const handleStatusChange = (value: string) => {
    if (value === "NONE") {
      setSelectedFilters((prev) => ({
        ...prev,
        status: null,
      }));
      return;
    }
    setSelectedFilters((prev) => ({
      ...prev,
      status: value,
    }));
  };

  const handleWorkOrderTypeChange = (value: string) => {
    if (value === "NONE") {
      setSelectedFilters((prev) => ({
        ...prev,
        taskCategory: null,
      }));
      return;
    }
    setSelectedFilters((prev) => ({
      ...prev,
      taskCategory: value,
    }));
  };

  const statusBaseOptions = [
    { value: "PENDING", label: "Scheduled" },
    { value: "WAITING FOR REVIEW", label: "Waiting for Review" },
  ];

  const cancelOption = { value: "NONE", label: "None" };

  const statusOptions = !shouldHideStatusFilter
    ? [...statusBaseOptions, cancelOption]
    : [
        ...statusBaseOptions,
        { value: "COMPLETED", label: "Completed" },
        { value: "SKIPPED", label: "Skipped" },
        cancelOption,
      ];

  const baseOptions = [
    { value: "PREVENTIVE_MAINTENANCE", label: "Preventive Maintenance" },
    { value: "CORRECTIVE_MAINTENANCE", label: "Corrective Maintenance" },
  ];

  const workOrderOptions =
    tableFilters.orderTypeTab !== "all"
      ? [...baseOptions, cancelOption]
      : [...baseOptions, { value: "TASK", label: "Task" }, cancelOption];

  const closeTableFilterModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.FILTER_TABLE_MODAL,
    });
  };

  const onOpenTableFilterModal = () => {
    ModalServiceInstance.open(AlectifyModal, tableFilterModalConfig);
  };

  const tableFilterModalConfig = {
    name: MODAL_NAMES.FILTER_TABLE_MODAL,
    title: AlectifyText.FILTERS,
    onCancel: handleCancel,

    children: (
      <Spin spinning={isLoading}>
        <div>
          <Row gutter={[12, 24]}>
            {enableFilters.dueDate && (
              <React.Fragment key="dueDate">
                <Col className="font-size-14" span={6}>
                  Due Date:
                </Col>
                <Col span={18} className="d-flex justify-flex-end">
                  <Space>
                    <span
                      className={`button-filters ${
                        activeButtons.dueDate === "ASC" ? "active" : ""
                      }`}
                      onClick={() => clickFilter("dueDate", "ASC")}
                    >
                      Ascending
                    </span>
                    <span
                      className={`button-filters ${
                        activeButtons.dueDate === "DESC" ? "active" : ""
                      }`}
                      onClick={() => clickFilter("dueDate", "DESC")}
                    >
                      Descending
                    </span>

                    <span
                      className="button-filters"
                      onClick={() => handleIndividualReset("dueDate")}
                    >
                      None
                    </span>
                  </Space>
                </Col>
              </React.Fragment>
            )}

            {enableFilters.createdAt && (
              <React.Fragment key="createdAt">
                <Col className="font-size-14" span={6}>
                  Created at:
                </Col>
                <Col span={18} className="d-flex justify-flex-end">
                  <Space>
                    <span
                      className={`button-filters ${
                        activeButtons.createdAt === "ASC" ? "active" : ""
                      }`}
                      onClick={() => clickFilter("createdAt", "ASC")}
                    >
                      Ascending
                    </span>
                    <span
                      className={`button-filters ${
                        activeButtons.createdAt === "DESC" ? "active" : ""
                      }`}
                      onClick={() => clickFilter("createdAt", "DESC")}
                    >
                      Descending
                    </span>

                    <span
                      className="button-filters"
                      onClick={() => handleIndividualReset("createdAt")}
                    >
                      None
                    </span>
                  </Space>
                </Col>
              </React.Fragment>
            )}

            {enableFilters.status && (
              <React.Fragment key="status">
                <Col className="font-size-14" span={6}>
                  Status:
                </Col>
                <Col span={18} className="d-flex justify-flex-end">
                  <AlectifySelect
                    name="status"
                    noFormItem
                    value={
                      tableFilters.statusShifterButtons === "COMPLETED" ||
                      tableFilters.statusShifterButtons === "SKIPPED"
                        ? tableFilters.statusShifterButtons
                        : selectedFilters.status || undefined
                    }
                    className="status-container"
                    onChange={handleStatusChange}
                    placeholder="Please Select"
                    options={statusOptions}
                    disabled={
                      tableFilters.statusShifterButtons === "COMPLETED" ||
                      tableFilters.statusShifterButtons === "SKIPPED"
                    }
                  />
                </Col>
              </React.Fragment>
            )}

            {enableFilters.taskCategory && (
              <React.Fragment key="taskCategory">
                <Col className="font-size-14" span={6}>
                  Work Order Type:
                </Col>
                <Col span={18} className="d-flex justify-flex-end">
                  <AlectifySelect
                    name="taskCategory"
                    noFormItem
                    value={
                      tableFilters.orderTypeTab === "tasks"
                        ? "TASK"
                        : selectedFilters.taskCategory || undefined
                    }
                    className="status-container"
                    onChange={handleWorkOrderTypeChange}
                    placeholder="Please Select"
                    options={workOrderOptions}
                    disabled={tableFilters.orderTypeTab === "tasks"}
                  />
                </Col>
              </React.Fragment>
            )}
          </Row>
        </div>
      </Spin>
    ),
    footer: (
      <div className="d-flex justify-content-space-between">
        <AlectifyButton
          key="cancel"
          text={AlectifyText.RESET_ALL}
          type="text"
          className="white-background-red-text"
          htmlType="button"
          onClick={() => handleResetAll()}
        />
        <AlectifyButton
          key="save"
          text={`${AlectifyText.APPLY_FILTERS} (${filterCount})`}
          type="default"
          className="blue-background-white-text"
          htmlType="button"
          onClick={() => handleSubmit()}
        />
      </div>
    ),
  };

  return (
    <div className="table-filter-container">
      <div
        className={`filter-button ${
          filterCount > 0 ? "active-filter-button" : ""
        }`}
        onClick={onOpenTableFilterModal}
      >
        <FilterIcon /> <span>{AlectifyText.FILTERS}</span>
      </div>
    </div>
  );
};

export default memo(AlectifyTableFilters);
