import { Breadcrumb, Col, Row, Space, Tabs, TabsProps, Typography } from "antd";
import PmExternalIcon from "components/icons/PmExternalIcon";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { IRootState } from "redux/rootReducer";
import { ROUTES } from "routes/Routes.constants";
import AlectifyText from "static/texts.json";
import PmExternal from "./PmExternal";
import Tasks from "./Tasks";
import { isExternalUser, sumValues } from "utils/helpers";
import TasksIcon from "components/icons/TasksIcon";
import "./WorkOrder.styles.scss";
import ProcedureViewSwitcher from "pages/procedures/procedures-listing/ProcedureViewSwitcher";
import {
  getPmExternal,
  setWorkOrderCardSwitcher,
} from "redux/components/pm-external/sources";
import AllWorkOrderIcon from "components/icons/AllWorkOrderIcon";
import AllWorkOrders from "./AllWorkOrders";
import AlectifyButton from "components/shared/button";
import { PlusOutlined } from "@ant-design/icons";
import { ITask } from "redux/components/tasks";
import { useForm } from "antd/lib/form/Form";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import WorkOrderCreateEdit from "pages/work-order/WorkOrderCreateEdit";
import { PM_TYPES } from "redux/components/pm-external";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { MESSAGES } from "constants/messages";
import { cancelPMApiRequests } from "utils/client";
import { SERVICE_UNIQUE_ID } from "utils/constants";
import { fetchTasks } from "redux/components/tasks/sources";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { PM_STATUS } from "enums";
import { PAGINATION } from "constants/index";
import { setTableFilters } from "redux/components/table-filters/sources";

const MyWorkOrders: React.FC = () => {
  const {
    common,
    auth: { user },
    tasks,
    pmExternal,
  } = useSelector((state: IRootState) => state);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [FormInstance] = useForm();

  const [validationMessage, setValidationMessage] = useState<string | null>(
    null,
  );

  const activeTab =
    new URLSearchParams(location.search).get("page") || "pm-external";

  const handleSwitchView = (value: boolean) => {
    dispatch(setWorkOrderCardSwitcher(value));
  };

  const onModalClose = () => {
    FormInstance.resetFields();
    setValidationMessage(null);
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
    });
  };
  const getTasks = (options?: IPagination, requestId?: string | null) => {
    if (
      options?.status === PM_STATUS.COMPLETED ||
      options?.status?.toString() === PM_STATUS.COMPLETED
    ) {
      options.status = [PM_STATUS.COMPLETED] as any;
      // setFilter(options?.status as any);
    } else if (
      options?.status === PM_STATUS.SKIPPED ||
      options?.status?.toString() === PM_STATUS.SKIPPED
    ) {
      options.status = [PM_STATUS.SKIPPED] as any;
      // setFilter(options?.status as any);
    } else {
      if (options) {
        options.status = [
          PM_STATUS.PENDING,
          PM_STATUS.WAITING_FOR_REVIEW,
          PM_STATUS.INPROGRESS,
        ] as any;
        // setFilter(options?.status as any);
      }
    }

    const assigneesArray =
      typeof options?.assignees === "string"
        ? options?.assignees.split(",")
        : [];
    delete options?.assignees;
    let approvers: any[] = [];
    if (common.showMyWorkOrders) {
      approvers = [user.id];
    }

    let orderField, orderBy;
    if (typeof options?.orderBy === "string") {
      const [field, direction] = options.orderBy.split(" ");
      orderField = field;
      orderBy = direction;
    }
    const params = {
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      status: [
        PM_STATUS.PENDING,
        PM_STATUS.WAITING_FOR_REVIEW,
        PM_STATUS.INPROGRESS,
      ],
      assignees: [user.id],
      approvers: [user.id],
      createdById: user.id,
      ...options,
      // ...(props.defaultParams ?? {}),
      orderField,
      orderBy,
    };

    if (activeTab === "tasks" || location.search.includes("tasks")) {
      cancelPMApiRequests(
        `${SERVICE_UNIQUE_ID.UNIQUE_FETCH_TASKS}${requestId && requestId}`,
      );
      dispatch(
        fetchTasks(
          common.activeMasterProject?.id || "all",
          "all",
          params,
          PM_TYPES.TASK,
          requestId,
        ),
      );
    } else if (
      activeTab === "pm-external" ||
      location.search.includes("pm-external")
    ) {
      cancelPMApiRequests(SERVICE_UNIQUE_ID.UNIQUE_FETCH_PM_EXTERNAL);
      dispatch(
        getPmExternal(common.activeMasterProject?.id || "all", "all", params),
      );
    } else if (activeTab === "all" || location.search.includes("all")) {
      cancelPMApiRequests(
        `${SERVICE_UNIQUE_ID.UNIQUE_FETCH_TASKS}${requestId && requestId}`,
      );
      dispatch(
        fetchTasks(
          common.activeMasterProject?.id || "all",
          "all",
          params,
          "all",
          requestId,
        ),
      );
    }
    // dispatch(getPmsCounts(common.activeMasterProject?.id, "all", params));
  };
  const modalConfig = {
    name: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
    className: MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
    title: AlectifyText.CREATE_WORK_ORDER,
    width: 800,
    style: { top: 30 },
    okText: "Submit",
    destroyOnClose: true,
    footer: (
      <Space>
        {validationMessage && (
          <span className="alectify-wo-validation-message">
            {validationMessage}
          </span>
        )}
        <AlectifyButton
          text={AlectifyText.CANCEL}
          type="default"
          className="alectify-default-button"
          onClick={onModalClose}
        />
        <AlectifyButton
          text={"Submit"}
          type="primary"
          onClick={async () => {
            try {
              await FormInstance.validateFields();
              setValidationMessage(null);
              FormInstance.submit();
            } catch (ex: any) {
              if (!isEmpty(ex.errorFields)) {
                if (ex.errorFields.length > 1) {
                  setValidationMessage(
                    MESSAGES.WORK_ORDER.MULTIPLE_FIELDS_VALIDATION,
                  );
                } else {
                  setValidationMessage(ex.errorFields[0].errors[0]);
                }
              }
            }
          }}
        />
      </Space>
    ),
    onCancel: onModalClose,
    children: (
      <WorkOrderCreateEdit
        FormInstance={FormInstance}
        editing={false}
        editType={""}
        taskId={""}
        subProjectId={""}
        masterProjectId={""}
        isTask={false}
        copying={false}
        type={
          activeTab === "tasks"
            ? PM_TYPES.TASK
            : activeTab === "pm-external"
            ? PM_TYPES.PM_EXTERNAL
            : PM_TYPES.ALL
        }
        callbackEffects={getTasks}
      />
    ),
  };
  const openCreationModal = () => {
    FormInstance.resetFields();
    ModalServiceInstance.open(AlectifyModal, modalConfig);
  };

  useEffect(() => {
    ModalServiceInstance.updateModalProps(
      MODAL_NAMES.WORK_ORDER_CREATE_MODAL,
      modalConfig,
    );
  }, [validationMessage]);

  const items: TabsProps["items"] = [
    {
      key: "all",
      label: (
        <>
          <AllWorkOrderIcon />{" "}
          {`${AlectifyText.ALL_WORK_ORDERS} (${sumValues([
            pmExternal.pendingCounts,
            pmExternal.completedCounts,
            pmExternal.skippedCounts,
            tasks.pendingCounts,
            tasks.completedCounts,
            tasks.skippedCounts,
          ])})`}
        </>
      ),
      children: (
        <AllWorkOrders
          scroll={{ x: "max-content", y: "calc(100vh - 324px)" }}
        />
      ),
    },
    {
      key: "pm-external",
      label: (
        <>
          <PmExternalIcon />{" "}
          {`${AlectifyText.PM_EXTERNAL} (${sumValues([
            pmExternal.pendingCounts,
            pmExternal.completedCounts,
            pmExternal.skippedCounts,
          ])})`}
        </>
      ),
      children: (
        <PmExternal scroll={{ x: "max-content", y: "calc(100vh - 324px)" }} />
      ),
    },
    {
      key: "tasks",
      label: (
        <>
          <TasksIcon />{" "}
          {`${AlectifyText.TASKS} (${sumValues([
            tasks.pendingCounts,
            tasks.completedCounts,
            tasks.skippedCounts,
          ])})`}
        </>
      ),
      children: (
        <Tasks scroll={{ x: "max-content", y: "calc(100vh - 324px)" }} />
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      setTableFilters({
        orderTypeTab: activeTab,
      }),
    );
  }, [dispatch, activeTab]);

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: "Home",
            href: `#`,
            onClick: () => {
              navigate(
                `${isExternalUser(user) ? ROUTES.MY_ITEMS : ROUTES.PROJECT}`,
              );
            },
          },
          {
            title: common.showMyWorkOrders
              ? AlectifyText.WORK_ORDERS
              : AlectifyText.ALL_WORK_ORDERS,
          },
        ]}
      />
      <div className="sub-project-tabs">
        <Col span={24}>
          <Row justify={"space-between"} align={"middle"}>
            <Col
              span={12}
              className="d-flex align-items-center work-order-title"
            >
              <Typography.Title level={4}>
                {common.showMyWorkOrders
                  ? AlectifyText.MY_WORK_ORDERS
                  : AlectifyText.ALL_WORK_ORDERS}
              </Typography.Title>
              <ProcedureViewSwitcher
                onChange={handleSwitchView}
                tableView={pmExternal.activeWorkOrderCardSwitcher}
              />
            </Col>
            {!isExternalUser(user) && (
              <Col span={12} className="text-align-right">
                <AlectifyButton
                  text={AlectifyText.CREATE_WORK_ORDER}
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={openCreationModal}
                />
              </Col>
            )}
            {/* {!isExternalUser(user) && (
              <Col span={12} style={{ textAlign: "right" }}>
                <Space className="alectify-work-order-tabs">
                  <Radio.Group
                    options={
                      isExternalUser(user)
                        ? [MY_WORK_ORDER_OPTIONS[0]]
                        : MY_WORK_ORDER_OPTIONS
                    }
                    onChange={onButtonChange}
                    value={common.showMyWorkOrders}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Space>
              </Col>
            )} */}
          </Row>
        </Col>

        <Tabs
          defaultActiveKey={activeTab}
          items={items}
          onChange={(tabId) => {
            navigate({ search: `page=${tabId}` });
          }}
        />
      </div>
    </>
  );
};

export default MyWorkOrders;
