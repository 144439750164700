import {
  Avatar,
  Checkbox,
  Col,
  Divider,
  Row,
  Space,
  Upload,
  UploadFile,
} from "antd";
import { IProcedureChecklistCardProps } from "./Procedure.interface";
import { IProcedureCheckList } from "services/procedures/procedures.interface";
import { ClockIcon, WarningIcon } from "components/icons";
import AlectifyText from "static/texts.json";

import TextToLink from "../text-to-link";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { createRef, useEffect, useState } from "react";

import { isEmpty } from "lodash";
import AlectifyHoursPicker from "../estimated-hours-input";
import AlectifyInput from "../input";
import AlectifyButton from "../button";
import {
  convertStringIntoHrsAndMins,
  extractFileNameFromSignedURL,
  // extractFilePathFromSignedURL,
  truncateFileName,
} from "utils/helpers";
import AttachmentIcon from "components/icons/AttachmentIcon";
import { UploadChangeParam } from "antd/lib/upload";
import CrossCircleIcon from "components/icons/CrossCircleIcon";
import EditIcon from "components/icons/EditIcon";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ConfirmationModal from "../confirmation/Confirmation";
import { MESSAGES } from "constants/messages";
import { FileFilled } from "@ant-design/icons";
import ViewImage from "../view-image/ViewImage";
import PreviewFile from "../preview-file";

const ProcedureCheckListCard: React.FC<IProcedureChecklistCardProps> = (
  props: IProcedureChecklistCardProps,
) => {
  const [duration, setDuration] = useState<{ [key: string]: string }>({});
  const [comments, setComments] = useState<{ [key: string]: string }>({});
  const [fileInputRefs, setFileInputRefs] = useState<{
    [key: string]: React.RefObject<HTMLInputElement | null>;
  }>({});
  const [selectedFiles, setSelectedFiles] = useState<{
    [key: string]: File | null;
  }>({});
  const [editingStep, setEditingStep] = useState<string | null>(null);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const onAddDuration = (id: string) => {
    setEditingStep(id); // Show the duration fields when clicking "Add Time"
    // Pre-populate the duration if available
    setDuration({
      ...duration,
      [id]:
        props.procedureSteps.find((step) => step.id === id)?.durationMins || "",
    });
    // Clear comments if any
    setComments({
      ...comments,
      [id]: "",
    });
  };

  const handleAttachFileClick = (id: string) => {
    if (fileInputRefs[id].current) {
      fileInputRefs[id]?.current?.click();
    }
  };

  const handleFileChange = (
    steps: IProcedureCheckList,
    info: UploadChangeParam<UploadFile<any>>,
  ) => {
    const file = info.fileList[0].originFileObj as File;
    setSelectedFiles({
      ...selectedFiles,
      [steps.id]: file,
    });
    props.onStatusUpdate(
      steps.id,
      steps.isChecked as any,
      steps.durationMins,
      steps.comments,
      file,
    );
  };

  const handleEditClick = (id: string) => {
    setEditingStep(id);
    // Pre-populate the duration and comments if available
    setDuration({
      ...duration,
      [id]:
        props.procedureSteps.find((step) => step.id === id)?.durationMins || "",
    });
    setComments({
      ...comments,
      [id]: props.procedureSteps.find((step) => step.id === id)?.comments || "",
    });
  };

  const handleCancelEdit = () => {
    setEditingStep(null);
    // Clear the pre-populated duration and comments
    setDuration({});
    setComments({});
  };

  const handleSaveEdit = (id: string) => {
    // If comments[id] is empty, set it to null before calling onStatusUpdate
    const updatedComment = comments[id]?.trim() === "" ? null : comments[id];

    // Call the onStatusUpdate function with the modified comment
    props.onStatusUpdate(
      id,
      props.procedureSteps.find((step) => step.id === id)?.isChecked || false,
      duration[id],
      updatedComment as any,
      selectedFiles[id],
    );

    // Reset editing state
    setEditingStep(null);
    setDuration({});
    setComments({});
  };

  const onRemoveFile = (steps: IProcedureCheckList) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.PROCEDURE_STEPS_FILE_REMOVE_MODAL,
      footer: false,
      title: AlectifyText.CONFIRMATION,
      children: (
        <ConfirmationModal
          message={MESSAGES.PROCEDURE.REMOVE_STEPS_FILE_WARNING}
          icon={WarningIcon}
          onCancel={() => {
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.PROCEDURE_STEPS_FILE_REMOVE_MODAL,
            });
          }}
          onConfirm={() => {
            props.onStatusUpdate(
              steps.id,
              steps.isChecked as any,
              steps.durationMins,
              steps.comments,
              null,
            );
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.PROCEDURE_STEPS_FILE_REMOVE_MODAL,
            });
          }}
        />
      ),
    });
  };

  useEffect(() => {
    // checking proceduresteps gets updated it would nullify all the duration object so that state can be reset
    // setDuration({});
    // setComments({});
    setSelectedFiles({});
    // Create refs for each step
    const newFileInputRefs: {
      [key: string]: React.RefObject<HTMLInputElement | null>;
    } = {};
    props.procedureSteps.forEach((step) => {
      newFileInputRefs[step.id] = createRef();
    });
    setFileInputRefs(newFileInputRefs);
  }, [props.procedureSteps]);
  // console.log(extractFileNameFromSignedURL(props.procedureSteps[0]?.imageUrl || ""), extractFilePathFromSignedURL(props.procedureSteps[0]?.imageUrl || ""))

  useEffect(() => {
    const allChecked = props.procedureSteps.every((step) => step.isChecked);
    setSelectAllChecked(allChecked);
  }, [props.procedureSteps]);

  const handleSelectAllChange = (e: CheckboxChangeEvent) => {
    const isChecked = e.target.checked;
    setSelectAllChecked(isChecked);
    // props.procedureSteps.forEach((step) => {
    //   props.onStatusUpdate(step.id, isChecked, step.durationMins, step.comments);
    // });
    props.updateAllStatus(isChecked);
  };

  const handleStepChange = (step: IProcedureCheckList, checked: boolean) => {
    props.onStatusUpdate(step.id, checked, step.durationMins, step.comments);
  };
  return (
    <Row
      justify={"start"}
      align={"middle"}
      className="alectify-procedure-checklist-card-container"
    >
      <Col span={24}>
        <Row justify={"start"} align={"middle"} gutter={20}>
          <Checkbox
            checked={selectAllChecked}
            onChange={handleSelectAllChange}
            disabled={props.isDisabled}
            className="alectify-procedure-checklist-check-all"
          >
            Check All
          </Checkbox>
          {props.procedureSteps.map((steps: IProcedureCheckList) => {
            return (
              <Col
                span={24}
                className={`alectify-procedure-checklist-card mb-10 ${
                  steps.isChecked ? "checked" : ""
                }`}
                key={steps.id}
              >
                <Row justify={"start"} align={"top"} gutter={20}>
                  <Col span={1}>
                    <Checkbox
                      checked={steps.isChecked}
                      onChange={(e: CheckboxChangeEvent) =>
                        props.onStatusUpdate(
                          steps.id,
                          e.target.checked,
                          steps.durationMins,
                          steps.comments,
                        )
                      }
                      disabled={props.isDisabled}
                    />
                  </Col>
                  <Col span={18}>
                    <Row justify={"start"} align={"middle"} gutter={0}>
                      <Col span={24} className="ml-10">
                        {steps.order}
                      </Col>
                      <Col span={24} className="ml-10 mt-5 text-align-justify">
                        {steps.name}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    span={5}
                    className="alectify-procedure-steps-default-image"
                  >
                    {steps.defaultImageUrl && (
                      <Space direction="vertical">
                        <div id="idToExclude">
                          <Avatar
                            src={steps.defaultImageUrl}
                            size={60}
                            className="cursor-pointer alectify-avatar-clickable-border"
                            onClick={() => {
                              ModalServiceInstance.open(AlectifyModal, {
                                name: MODAL_NAMES.VIEW_IMAGE_MODAL,
                                title: AlectifyText.VIEW_IMAGE,
                                children: (
                                  <ViewImage
                                    imageUrl={steps.defaultImageUrl || ""}
                                  />
                                ),
                                onCancel: () => {
                                  ModalServiceInstance.close(AlectifyModal, {
                                    name: MODAL_NAMES.VIEW_IMAGE_MODAL,
                                  });
                                },
                                footer: null,
                              });
                            }}
                          />
                        </div>
                      </Space>
                    )}
                  </Col>
                  <Col span={24}>
                    <Divider className="mt-10 mb-10" />
                  </Col>
                  <Col span={24}>
                    <Row justify={"space-between"}>
                      <Col span={12} className="alectify-flex-start">
                        {props.procedure.comments &&
                          editingStep !== steps.id && (
                            <>
                              <span id="idToExclude">
                                <ClockIcon
                                  fill={steps.durationMins ? "#666666" : ""}
                                />
                              </span>
                              {isEmpty(steps.durationMins) ? (
                                <>
                                  <div id="child-to-replace">
                                    <TextToLink
                                      text={AlectifyText.ADD_TIME}
                                      className={`${
                                        props.isDisabled
                                          ? "alectify-text-to-link-disabled"
                                          : "alectify-procedure-link-text "
                                      }`}
                                      onClick={() => {
                                        !props.isDisabled &&
                                          onAddDuration(steps.id);
                                      }}
                                    />
                                  </div>
                                  {/* <span className="alectify-grey-text ml-5">
                                    (Optional)
                                  </span> */}
                                </>
                              ) : (
                                <Space>
                                  <span className="alectify-grey-text ml-5">
                                    Time:{" "}
                                    {convertStringIntoHrsAndMins(
                                      steps.durationMins || "",
                                    )}
                                  </span>
                                  <span
                                    id="idToExclude"
                                    onClick={() =>
                                      !props.isDisabled &&
                                      handleEditClick(steps.id)
                                    }
                                    className={`alectify-procedure-comment-edit-icon ${
                                      props.isDisabled
                                        ? "alectify-text-to-link-disabled"
                                        : "cursor-pointer"
                                    }`}
                                  >
                                    <EditIcon />
                                  </span>
                                </Space>
                              )}
                            </>
                          )}
                      </Col>
                      <Col
                        span={12}
                        className="text-align-right alectify-flex-end"
                      >
                        {props.procedure.fileUpload &&
                        isEmpty(selectedFiles[steps.id]) &&
                        isEmpty(steps.imageUrl) ? (
                          <span>
                            <Upload
                              showUploadList={false}
                              beforeUpload={() => false}
                              multiple={false}
                              onChange={(info) =>
                                !props.isDisabled &&
                                handleFileChange(steps, info)
                              }
                              disabled={props.isDisabled}
                            >
                              <span id="idToExclude">
                                <AttachmentIcon />
                                <TextToLink
                                  text={AlectifyText.ATTACH_FILE}
                                  className={`${
                                    props.isDisabled
                                      ? "alectify-text-to-link-disabled"
                                      : "alectify-procedure-link-text "
                                  }`}
                                  onClick={() => {
                                    !props.isDisabled &&
                                      handleAttachFileClick(steps.id);
                                  }}
                                />
                              </span>
                            </Upload>
                            {/* <span className="alectify-grey-text ml-5">
                              (Optional)
                            </span> */}
                          </span>
                        ) : (
                          selectedFiles[steps.id]?.name ||
                          (steps.imageUrl?.split("/")[2] && (
                            <Space direction="horizontal">
                              <span>
                                <FileFilled />
                              </span>
                              <PreviewFile
                                fileName={truncateFileName(
                                  selectedFiles[steps.id]?.name ||
                                    extractFileNameFromSignedURL(
                                      steps.imageUrl,
                                    ) ||
                                    "",
                                  20,
                                )}
                                fileUrl={steps.imageUrl}
                                isActivevalue={true}
                                isSigned
                              />

                              {!props.isDisabled && (
                                <span
                                  id="idToExclude"
                                  className="cursor-pointer"
                                  onClick={() => onRemoveFile(steps)}
                                >
                                  <CrossCircleIcon />
                                </span>
                              )}
                            </Space>
                          ))
                        )}
                      </Col>
                      {!isEmpty(steps.comments) && editingStep !== steps.id && (
                        <Col
                          span={24}
                          className="alectify-procedure-steps-comments"
                        >
                          <strong>Comments: </strong>
                          <span className="alectify-grey-text text-align-justify">
                            {steps.comments}
                          </span>
                        </Col>
                      )}
                    </Row>
                  </Col>

                  {duration.hasOwnProperty(steps.id) &&
                    editingStep === steps.id && (
                      <>
                        <Col span={24}>
                          <strong>Add Time And Comments:</strong>
                          <AlectifyHoursPicker
                            onChange={(v) => {
                              setDuration({
                                ...duration,
                                [steps.id]: v,
                              });
                            }}
                            value={duration[steps.id] as any}
                          />
                        </Col>
                        <Col span={24} className="mt-5">
                          <AlectifyInput
                            name="note"
                            type="text"
                            placeholder="Add Note (Optional)"
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>,
                            ) =>
                              setComments({
                                ...comments,
                                [steps.id]: event.target.value,
                              })
                            }
                            value={comments[steps.id] as any}
                            defaultValue={steps.comments}
                            className="mb-5"
                          />
                        </Col>
                        <Col span={24} className="mt-5">
                          <Row justify={"end"} align={"middle"}>
                            <Col span={4} className="text-align-right">
                              <AlectifyButton
                                name="save"
                                type="primary"
                                text="Save"
                                onClick={() => {
                                  handleSaveEdit(steps.id);
                                }}
                                className="mr-5 text-align-right"
                              />
                            </Col>
                            <Col span={3}>
                              <AlectifyButton
                                name="cancel"
                                type="default"
                                text="Cancel"
                                onClick={handleCancelEdit}
                                className="alectify-default-button ml-5"
                              />
                            </Col>
                          </Row>
                        </Col>
                        {/* <Col span={12} className="mt-5">
                        
                        </Col> */}
                      </>
                    )}
                </Row>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};
ProcedureCheckListCard.defaultProps = {
  isDisabled: false,
};
export default ProcedureCheckListCard;
