import React, { ReactNode } from "react";
import { isEmpty, truncate } from "lodash";
import { ITask } from "redux/components/tasks";
import { Avatar, Badge, Dropdown, Space, Tooltip, message } from "antd";
import {
  displayDateTime,
  enumToTile,
  getFullUserName,
  truncateText,
} from "utils/helpers";
import {
  TaskStatusEnum,
  CommentReferenceEnum,
  TaskPriorityEnum,
  PM_STATUS,
  PME_ACTION_REQUIRED_ENUM,
  MaintenanceCategoriesEnum,
} from "enums";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import {
  PaperClipOutlined,
  MessageOutlined,
  EllipsisOutlined,
  CloseOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { actionItems } from "../TaskTable.rules";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import TextToLink from "components/shared/text-to-link";
import AvatarGroup from "components/shared/avatar-group";
// import AssetDetail from "components/shared/asset-detail";
import AlectifyText from "static/texts.json";
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
// import PackageDetailAssets from "components/shared/package-detail-assets";
import useMyWorkOrdersColumns from "pages/my-work-orders/effects/useMyWorkOrdersColumns";
import Comments from "components/shared/comments";
import {
  ApprovedTag,
  ApproverTag,
  AssetPackageTag,
  AssetTag,
  AssigneeTag,
  CompletedTag,
  ScheduledTag,
  WaitingForApprovalTag,
  WaitingForReviewTag,
} from "components/shared/tags";
import DangerTag from "components/shared/tags/DangerTag";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";

import "assets/scss/global.scss"; //@Todo: need to add this file in to single style files, so it can be imported automatically
import {
  getPMEActionRequired,
  isDelayed,
} from "components/shared/pm-external-table/PmExternal.helpers";
import DelayedTag from "components/shared/tags/DelayedTag";
import OnTimeTag from "components/shared/tags/OnTimeTag";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { useNavigate } from "react-router-dom";
import ClosedTag from "components/shared/tags/ClosedTag";
import { CalendarDateIcon, UserIcon, WarningIcon } from "components/icons";
import { ITasksTableProps } from "../TasksTable.interface";
import { IProcedure } from "services/procedures/procedures.interface";
import StampCheckIcon from "components/icons/StampCheckIcon";
import { DONE_TASK_GREEN_COLOR } from "components/shared/phone-number/constants";
import { PM_STATUS_COMPONENTS } from "components/shared/pm-internal-table/effects/usePmInternalColumns";
import { IPmAssignees } from "redux/interfaces";
import { IPmExternal, PM_TYPES } from "redux/components/pm-external";
import PreventiveDocumentsAttachment from "components/shared/preventive-documents/PreventiveDocumentsAttachment";
import ProcedureDetail from "components/shared/procedure-detail";
import PmDetail from "components/shared/pm-detail";
import { undoTaskStatus } from "services/tasks/tasks.service";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import { MESSAGES } from "constants/messages";
import ReopenedIcon from "components/icons/ReopenedIcon";
import "../TasksTable.scss";
import AlectifyChip from "components/shared/chips/AlectifyChip";
import ViewImage from "components/shared/view-image/ViewImage";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import { ROUTES } from "routes/Routes.constants";
import { actions as NotificationActions } from "redux/components/notifications";
import useSimpleColumns from "./useSimpleColumns";
import RecurringIcon from "components/icons/RecurringIcon";
import AlectifyAiSummaryButton from "components/shared/ai-summary-button/AlectifyAISummaryButton";
import MultiAssetTag from "components/shared/tags/MultiAssetTag";
import AlectifyTable from "components/shared/table";
import NoAssetTag from "components/shared/tags/NoAssetTag";
import AlectifyButton from "components/shared/button";

export interface ITaskTableColumnHookProps extends ITasksTableProps {
  onEdit: (_: any, editing: boolean, editType: string, record: ITask) => void;
  filter: string[];
  optionsPersisted?: IPagination | null;
  showSelection?: boolean;
  fetchTasks: () => void;
  updateStatus: (pmId: IPmExternal | ITask, status: string) => void;
  openSubmitForReviewModal: (task: ITask) => void;
  removeTask: (id: string) => void;
  openSpareParts?: (task: IPmExternal | ITask) => void;
  onSelectWorkOrder?: (task: ITask) => void;
}

export const TASK_STATUS_COMPONENTS = {
  [TaskStatusEnum.PENDING]: <ScheduledTag />,
  [TaskStatusEnum.APPROVED]: <ApprovedTag />,
  [TaskStatusEnum.SCHEDULED]: <ScheduledTag />,
  [TaskStatusEnum.COMPLETED]: <ClosedTag />,
  [TaskStatusEnum.REVIEW_DECLINED]: <WaitingForReviewTag />,
  [TaskStatusEnum.WAITING_FOR_REVIEW]: <WaitingForReviewTag />,
  [TaskStatusEnum.WAITING_FOR_APPROVAL]: <WaitingForApprovalTag />,
};
export const taskStatusStepIcon = (
  currentStatus: string,
  statusToMove: string,
): ReactNode => {
  if (
    currentStatus === PM_STATUS.PENDING &&
    statusToMove === PM_STATUS.WAITING_FOR_REVIEW
  ) {
    return AlectifyText.SUBMIT_REVIEW;
    /*  <Row justify={"space-between"} align={"middle"}>
        <Col span={20}>{AlectifyText.SUBMIT_REVIEW}</Col>
        <Col span={4} className="text-align-right">
          <ForwardOutlined style={{ color: "#0954f1", fontSize: 15 }} />
        </Col>
      </Row> */
  } else if (
    currentStatus === PM_STATUS.INPROGRESS &&
    statusToMove === PM_STATUS.WAITING_FOR_REVIEW
  ) {
    return AlectifyText.SUBMIT_REVIEW;
    // <Row justify={"space-between"} align={"middle"}>
    //   <Col span={20}>{AlectifyText.SUBMIT_REVIEW}</Col>
    //   <Col span={4} className="text-align-right">
    //     <ForwardOutlined style={{ color: "#0954f1", fontSize: 15 }} />
    //   </Col>
    // </Row>
  } else if (
    currentStatus === PM_STATUS.PENDING &&
    statusToMove === PM_STATUS.INPROGRESS
  ) {
    return AlectifyText.IN_PROGRESS;
    // <Row justify={"space-between"} align={"middle"}>
    //   <Col span={12}>{AlectifyText.IN_PROGRESS}</Col>
    //   <Col span={12} className="text-align-right">
    //     <ForwardOutlined style={{ color: "#0954f1", fontSize: 15 }} />
    //   </Col>
    // </Row>
  } else if (
    /* else if (
    currentStatus === PM_STATUS.INPROGRESS &&
    statusToMove === PM_STATUS.PENDING
  ) {
    return AlectifyText.SCHEDULED;
    <Row justify={"space-between"} align={"middle"}>
      <Col span={12}>{AlectifyText.SCHEDULED}</Col>
      <Col span={12} className="text-align-right">
        <BackwardOutlined style={{ color: "red", fontSize: 15 }} />
      </Col>
    </Row>
  }  */
    /* else if (
    currentStatus === PM_STATUS.WAITING_FOR_REVIEW &&
    statusToMove === PM_STATUS.INPROGRESS
  ) {
    return (
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>{AlectifyText.IN_PROGRESS}</Col>
        <Col span={12} className="text-align-right">
          <BackwardOutlined style={{ color: "red", fontSize: 15 }} />
        </Col>
      </Row>
    );
  }  */
    currentStatus === PM_STATUS.WAITING_FOR_REVIEW &&
    statusToMove === PM_STATUS.COMPLETED
  ) {
    return AlectifyText.REVIEW_COMPLETE;
    // <Row justify={"space-between"} align={"middle"}>
    //   <Col span={20}>{AlectifyText.REVIEW_COMPLETE}</Col>
    //   <Col span={4} className="text-align-right">
    //     <ForwardOutlined style={{ color: "#0954f1", fontSize: 15 }} />
    //   </Col>
    // </Row>
  }
  return <></>;
};
export const TASK_PROIRITY_COMPONENTS = {
  [TaskPriorityEnum.NORMAL]: <CompletedTag text={AlectifyText.NORMAL} />,
  [TaskPriorityEnum.CRITICAL]: <DangerTag text={AlectifyText.CRITICAL} />,
};

export const ACTION_REQUIRED_COMPONENTS = (
  action: PME_ACTION_REQUIRED_ENUM | null,
) => {
  switch (action) {
    case PME_ACTION_REQUIRED_ENUM.ASSIGNEE:
      return <AssigneeTag text={action} />;
    case PME_ACTION_REQUIRED_ENUM.APPROVERS:
      return <ApproverTag />;
    default:
      return <></>;
  }
};

export const getTaskStatusIcon = (procedure: IProcedure): JSX.Element => {
  const isAllChecked =
    procedure.procedureStepCheckedTotalCount ===
    procedure.procedureStepTotalCount;
  return isAllChecked ? (
    <StampCheckIcon fill={DONE_TASK_GREEN_COLOR} />
  ) : (
    <StampCheckIcon />
  );
};

const useTasksColumn = (props: ITaskTableColumnHookProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTaskType = props.type === PM_TYPES.TASK;

  const onCell = (record: any, rowIndex: number) => ({
    style: {
      cursor: "pointer",
    },

    onClick: () => {
      goToPmDetail(record);
    },
  });
  const myItemsColumns = useMyWorkOrdersColumns({
    isTaskType: isTaskType,
    onCell: onCell,
  });
  const { pmExternal } = useSelector((state: IRootState) => state);

  const simpleItemsColumn = useSimpleColumns(props);

  const currentUser = useSelector((state: IRootState) => state.auth.user);
  const { showMyWorkOrders } = useSelector((state: IRootState) => state.common);
  const { projectTeamMembers } = useSelector(({ users }: IRootState) => users);

  const goToPmDetail = (task: ITask) => {
    const pmId = task.id;
    const masterProjectId = task?.project?.id;
    const subProjectId = task?.subProject?.id;
    const url = `${ROUTES.MY_ITEMS}/pm/${masterProjectId}/${subProjectId}/${pmId}?pmType=${task.pmType}`;
    dispatch(NotificationActions.toggleNotificationDropdown(false));
    navigate(url);
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: [DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER],
    });
  };

  const TASK_CATEGORY_MAP = {
    PREVENTIVE_MAINTENANCE: "PM",
    CORRECTIVE_MAINTENANCE: "CM",
    TASK: "Task",
  };
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.CREATED,
      key: "created",
      dataIndex: "createdAt",
      visible: isTaskType ? false : !props.myItemsColumns,
      ellipsis: true,
      width: 110,
      onCell,
      render: (target: any, record: ITask) => (
        <div className="d-flex justify-content-space-between">
          <div className="status-column">
            {record?.priority === TaskPriorityEnum.CRITICAL ? (
              <div className="critical-text">{AlectifyText.CRITICAL}</div>
            ) : null}
          </div>
          <Space
            direction="vertical"
            size={15}
            className={
              record?.priority === TaskPriorityEnum.CRITICAL ? "ml-30" : ""
            }
          >
            <div className="creator-container">
              <CalendarDateIcon />
              <span className="creator-name-date">
                {`${displayDateTime(
                  record?.createdAt || record?.createdAt,
                  true,
                  false,
                )}`}
              </span>
            </div>
            <div className="creator-container">
              {isEmpty(record?.createdBy?.image_url) ? (
                <UserIcon />
              ) : (
                <Avatar
                  src={record?.createdBy?.image_url}
                  size="small"
                  className="cursor-pointer"
                  onClick={() =>
                    ModalServiceInstance.open(AlectifyModal, {
                      name: "alectify-task-image-viewer",
                      title: "Preview",
                      footer: null,
                      onCancel: () => {
                        ModalServiceInstance.close(AlectifyModal, {
                          name: "alectify-task-image-viewer",
                        });
                      },
                      children: (
                        <ViewImage
                          imageUrl={
                            record?.createdBy?.image_url || NO_IMAGE_AVATAR
                          }
                          isDownloadable={
                            !isEmpty(record?.createdBy?.image_url)
                          }
                        />
                      ),
                    })
                  }
                />
              )}
              <span className="creator-name-date">
                {`${
                  record?.createdAt && !isEmpty(record?.createdAt)
                    ? getFullUserName(record?.createdBy as any)
                    : record?.createdBy && !isEmpty(record?.createdBy)
                    ? getFullUserName(record?.createdBy)
                    : "-"
                }`}
              </span>
              {record?.taskCategory && (
                <span>
                  {record?.taskCategory && (
                    <Tooltip title={`${enumToTile(record.taskCategory)}`}>
                      <AlectifyChip
                        text={
                          (TASK_CATEGORY_MAP as any)[record.taskCategory as any]
                        }
                        textColor={
                          record?.taskCategory ===
                          MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                            ? "red"
                            : "0954f1"
                        }
                        backgroundColor={
                          record?.taskCategory ===
                          MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                            ? "#fde8e8"
                            : "e8f1fd"
                        }
                        borderColor={
                          record?.taskCategory ===
                          MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE
                            ? "#ff0000"
                            : "e8f1fd"
                        }
                      />
                    </Tooltip>
                  )}
                </span>
              )}
            </div>
          </Space>
        </div>
      ),
    },

    {
      title: AlectifyText.CREATED,
      key: "created",
      dataIndex: "createdAt",
      visible: !isTaskType ? false : !props.myItemsColumns,
      ellipsis: true,
      width: 110,
      render: (target: any, record: ITask) => (
        <>
          <div className="status-column">
            {record?.priority === TaskPriorityEnum.CRITICAL ? (
              <div className="critical-text">{AlectifyText.CRITICAL}</div>
            ) : null}
          </div>
          <Space
            direction="vertical"
            size={15}
            className={
              record?.priority === TaskPriorityEnum.CRITICAL ? "ml-30" : ""
            }
          >
            <div className="creator-container">
              <CalendarDateIcon />
              <span className="creator-name-date">
                {`${displayDateTime(
                  record?.createdAt || record?.createdAt,
                  true,
                  false,
                )}`}
              </span>
            </div>
            <div className="creator-container">
              {isEmpty(record?.createdBy?.image_url) ? (
                <UserIcon />
              ) : (
                <Avatar src={record?.createdBy?.image_url} size="small" />
              )}
              <span className="creator-name-date">
                {`${
                  record?.createdAt && !isEmpty(record?.createdAt)
                    ? getFullUserName(record?.createdBy as any)
                    : record?.createdBy && !isEmpty(record?.createdBy)
                    ? getFullUserName(record?.createdBy)
                    : "-"
                }`}
              </span>
            </div>
          </Space>
        </>
      ),
    },
    {
      title: AlectifyText.WORKID_AND_TITLE,
      dataIndex: "workTitle",
      width: 150,
      visible: true,
      onCell,
      render: (value: string, task: ITask) => {
        const openProcedureCheckListDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 700,
            title: "Procedure",
            name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
            closable: true,
            closeIcon: <CloseOutlined />,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
              });
              props.fetchTasks();
            },
            showFooter: true,
            destroyOnClose: true,
            readOnly: true,
            cancelText: AlectifyText.CLOSE,
            children: (
              <ProcedureDetail
                procedure={task.procedure as any}
                taskId={task.id}
                isDisabled={
                  task.status === PM_STATUS.COMPLETED ||
                  task.status === PM_STATUS.SKIPPED
                }
              />
            ),
          });
        };
        return (
          <Space direction="horizontal" size={15}>
            <Avatar
              src={task.imageUrl || NO_IMAGE_AVATAR}
              className="cursor-pointer alectify-avatar-clickable-border"
              size={60}
              onClick={(e: any) => {
                e.stopPropagation();
                ModalServiceInstance.open(AlectifyModal, {
                  name: "alectify-task-image-viewer",
                  title: "Preview",
                  footer: null,
                  onCancel: () => {
                    ModalServiceInstance.close(AlectifyModal, {
                      name: "alectify-task-image-viewer",
                    });
                  },
                  children: (
                    <ViewImage
                      imageUrl={task.imageUrl || NO_IMAGE_AVATAR}
                      isDownloadable={!isEmpty(task.imageUrl)}
                    />
                  ),
                });
              }}
            />
            <Space direction="vertical" size={10}>
              <Space direction="horizontal">
                <Tooltip title={value}>
                  <TextToLink
                    className="text-to-link-options"
                    text={truncate(value, {
                      length: 30,
                      omission: "...",
                    })}
                    onClick={(e) => {
                      e.stopPropagation();
                      console.log(task);
                      DrawerServiceInstance.open(AlectifyDrawer, {
                        width: 480,
                        title: truncateText(task.workTitle, 40),
                        name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                        closable: true,
                        className: "bg-grey",
                        closeIcon: <CloseOutlined />,
                        onClose: () =>
                          DrawerServiceInstance.close(AlectifyDrawer, {
                            name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                          }),
                        children: (
                          <PmDetail
                            pmExternalRecord={task as any}
                            pmId={task.id}
                            pmType={
                              props.type === "ALL" ? task.pmType : props.type
                            }
                          />
                        ),
                      });
                    }}
                  />
                </Tooltip>
                {task.isReopened && (
                  <Tooltip title={AlectifyText.REOPENED}>
                    <span>
                      <ReopenedIcon />
                    </span>
                  </Tooltip>
                )}
              </Space>

              {(!isEmpty(task.procedure) && (
                <Tooltip
                  title={`${AlectifyText.PROCEDURE_CHECKLIST} ${task.procedure?.procedureStepCheckedTotalCount}/${task.procedure?.procedureStepTotalCount}`}
                >
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      openProcedureCheckListDrawer();
                    }}
                    className="cursor-pointer"
                  >
                    {truncate(task.workId, {
                      length: 20,
                      omission: "...",
                    })}{" "}
                    {!isEmpty(task.procedure) &&
                      getTaskStatusIcon(task.procedure)}
                  </span>
                </Tooltip>
              )) || (
                <Tooltip title={task.workId}>
                  <span>
                    {truncate(task.workId, {
                      length: 20,
                      omission: "...",
                    })}{" "}
                  </span>
                </Tooltip>
              )}
            </Space>
          </Space>
        );
      },
    },
    {
      title: AlectifyText.PARENT_OR_SUB_ASSET,
      key: "asset_type",
      dataIndex: "asset_type",
      width: 120,
      visible: true,
      onCell,
      render: (_, record: ITask) => {
        const assetCount = record.assets.length || 0;
        const areaCount = record.areas.length || 0;
        const total = assetCount + areaCount || 0;
        const displayText =
          total > 1 ? (
            <strong>{`Multiple Assets [${total}]`} </strong>
          ) : (
            (record.assets[0] as any)?.asset?.name ||
            (record.areas[0] as any)?.area?.name ||
            "-"
          );
        const isGeneric = record?.isGeneric;
        return (
          <Space direction="vertical" size={15}>
            <Tooltip
              title={
                (!isEmpty(record.assets) || !isEmpty(record.areas)) && "Assets"
              }
            >
              <TextToLink
                className="text-to-link-options"
                text={displayText}
                underline={!isGeneric}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isGeneric) {
                    const hasMultipleAssets = record.assets.length > 1;
                    const hasMultipleAreas = record.areas.length > 1;

                    if (hasMultipleAssets || hasMultipleAreas) {
                      // Open the modal with the list of assets and areas
                      ModalServiceInstance.open(AlectifyModal, {
                        name: MODAL_NAMES.ASSET_NAME_MODAL,
                        title: `Asset list of (${record.workTitle})`,
                        footer: null,
                        children: (
                          <AlectifyTable
                            onDataFetch={() => {}}
                            dataSource={[
                              ...record.assets.map((asset: any) => {
                                return { ...asset.asset, type: "Tag" };
                              }),
                              ...record.areas.map((ar: any) => {
                                return { ...ar.area, type: "PackageRoom" };
                              }),
                            ]}
                            total={[...record.assets, ...record.areas].length}
                            showPagination={false}
                            columns={[
                              {
                                dataIndex: "name",
                                title: "Name",
                                render: (value, assetRecord) => {
                                  const goToAssetDetails = () => {
                                    let url: string;
                                    if (assetRecord.type === "PackageRoom") {
                                      url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset-package/${assetRecord.id}?page=timeline`;
                                    } else {
                                      url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset/${assetRecord.id}?page=timeline`;
                                    }
                                    window.open(url, "_blank"); // Open in a new tab
                                  };
                                  return (
                                    <TextToLink
                                      text={
                                        <Space>
                                          <span>{value}</span>
                                          <ExportOutlined />
                                        </Space>
                                      }
                                      onClick={goToAssetDetails}
                                    />
                                  );
                                },
                              },
                            ]}
                          />
                        ),
                        onCancel: () =>
                          ModalServiceInstance.close(AlectifyModal, {
                            name: MODAL_NAMES.ASSET_NAME_MODAL,
                          }),
                      });
                    } else {
                      // Redirect directly to asset/area detail page
                      const singleAsset: any =
                        record.assets.length === 1 ? record.assets[0] : null;
                      const singleArea: any =
                        record.areas.length === 1 ? record.areas[0] : null;

                      if (singleAsset) {
                        const url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset/${singleAsset.asset.id}?page=timeline`;
                        window.open(url, "_blank");
                      } else if (singleArea) {
                        const url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset-package/${singleArea.area.id}?page=timeline`;
                        window.open(url, "_blank");
                      }
                    }
                  }
                }}
              />
            </Tooltip>
            {!isEmpty(record.assets) || !isEmpty(record.areas) ? (
              record.areas.length > 1 || record.assets.length > 1 ? (
                <MultiAssetTag />
              ) : record.areas.length === 1 ? (
                <AssetPackageTag />
              ) : record.assets.length === 1 ? (
                <AssetTag />
              ) : (
                <NoAssetTag />
              )
            ) : (
              <NoAssetTag />
            )}
          </Space>
        );
      },
    },
    /*  {
      title: AlectifyText.FREQUENCY,
      dataIndex: "isRecurring",
      key: "isRecurring",
      width: 90,
      visible: true,
      onCell,
      render: (isRecurring, record) => (
        <div className="pminternalcolumn-cell-tag">
          <Space direction="vertical">
            <span>
              {record?.frequency && isRecurring
                ? record.frequencyType === FrequencyTypeEnum.MONTHLY
                  ? `${record?.frequency} Month(s)`
                  : "-"
                : (record.frequencyType &&
                    capitalizeFirstLetter(record.frequencyType)) ||
                  "-"}
            </span>
            {isRecurring ? <RecurringTag /> : <NonRecurringTag />}
          </Space>
        </div>
      ),
    }, */
    {
      title: AlectifyText.STATUS,
      dataIndex: "dueDate",
      key: "dueDate",
      width: 110,
      visible: true,
      onCell,
      render: (value: Date, task: ITask) => {
        return (
          <Space direction="vertical" size={15}>
            {`Due: ${displayDateTime(value)}`}
            <Space>
              {PM_STATUS_COMPONENTS[task.status]}
              {task.isRecurring && (
                <Tooltip title={"Recurring"}>
                  <span>
                    <RecurringIcon />
                  </span>
                </Tooltip>
              )}
            </Space>
            {/* {`Completed at: ${displayDateTime(pmExternal.completedAt)}`} */}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.COMPLETION_DATE,
      dataIndex: "completedAt",
      key: "completedAt",
      width: 100,
      onCell,
      visible: props.filter.includes(PM_STATUS.COMPLETED),
      render: (value: Date, task: ITask) => {
        return (
          <Space direction="vertical" size={15}>
            {`On: ${displayDateTime(value)}`}
            {isDelayed(value, task.dueDate) ? <DelayedTag /> : <OnTimeTag />}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.ACTION_REQUIRED_BY,
      dataIndex: "assignees",
      key: "assignees",
      width: 90,
      onCell,
      filters: projectTeamMembers?.data.map((user) => ({
        text: getFullUserName(user),
        value: user.id,
      })),
      visible:
        props.filter.includes(PM_STATUS.PENDING) ||
        props.filter.includes(PM_STATUS.WAITING_FOR_REVIEW),
      render: (assignees: IPmAssignees[], record: IPmExternal) => {
        const actionRquired = getPMEActionRequired(record);
        const ACTION_REQUIRED = ACTION_REQUIRED_COMPONENTS(actionRquired);
        if (!isEmpty(assignees) || !isEmpty(record.approvers)) {
          return (
            <Space direction="vertical" size={5}>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <AvatarGroup
                  maxCount={2}
                  showImage
                  users={
                    actionRquired === PME_ACTION_REQUIRED_ENUM.ASSIGNEE
                      ? assignees.map(({ user }) => user)
                      : record.approvers?.map(({ user }) => user)
                  }
                />
              </div>
              {ACTION_REQUIRED}
            </Space>
          );
        }
        return <></>;
      },
    },
    {
      width: 45,
      dataIndex: "name",
      title: AlectifyText.MESSAGES,
      visible: true,
      searchable: true,

      render: (_, record) => {
        const subProject = record.subProject || null;

        const openAttachmentsDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 860,
            title: AlectifyText.ATTACHMENTS,
            name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
            closable: true,
            closeIcon: <CloseOutlined />,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
              });
              props.fetchTasks();
            },
            children: <PreventiveDocumentsAttachment details={record} />,
          });
        };

        const openCommentsDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 600,
            title: AlectifyText.MESSAGING_CENTER,
            name: DRAWER_CONSTANTS.DRAWER_COMMENTS,
            closable: true,
            closeIcon: <CloseOutlined />,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.DRAWER_COMMENTS,
              });
              props.fetchTasks();
            },
            children: (
              <Comments
                details={record && record}
                referenceId={record.id}
                subProjectId={subProject?.id}
                reference_type={CommentReferenceEnum.PM_EXTERNAL}
              />
            ),
            className: "alectify-drawer-task-comments",
          });
        };

        return (
          <Space
            direction="vertical"
            className="alectify-task-table-collaboration-column"
          >
            <Tooltip placement="topLeft" title={AlectifyText.MESSAGING_CENTER}>
              <Badge
                offset={[2, 0]}
                overflowCount={9}
                className="cursor-pointer"
                size="small"
              >
                <Space direction="horizontal">
                  <MessageOutlined
                    className="icon-font-size-18"
                    onClick={openCommentsDrawer}
                    style={{
                      color: "#0954f1",
                    }}
                  />
                  <span
                    className="alectify-task-table-collaboration-count-text"
                    onClick={openCommentsDrawer}
                  >
                    {record?.comments}
                  </span>
                </Space>
              </Badge>
            </Tooltip>

            <Tooltip placement="topLeft" title={AlectifyText.ATTACHMENTS}>
              <Badge
                offset={[2, 0]}
                overflowCount={9}
                className="cursor-pointer"
                size="small"
              >
                <Space direction="horizontal">
                  <PaperClipOutlined
                    className="icon-font-size-18"
                    onClick={openAttachmentsDrawer}
                    style={{
                      color: "#0954f1",
                    }}
                  />
                  <span
                    className="alectify-task-table-collaboration-count-text"
                    onClick={openAttachmentsDrawer}
                  >
                    {record?.documents}
                  </span>
                </Space>
              </Badge>
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: AlectifyText.AI_SUMMARY,
      dataIndex: "summary",
      key: "summary",
      width: 100,
      visible: true,
      render: (_, record: ITask) => {
        return (
          <AlectifyAiSummaryButton
            content={record.summary}
            name={record.workTitle}
            key={record.id}
          />
        );
      },
    },
    {
      width: 40,
      title: AlectifyText.ACTIONS,
      align: "center",
      fixed: "right",
      dataIndex: "",
      visible: true,
      render: (_, task: ITask) => {
        if (!props.showSelection) {
          const openAttachmentsDrawer = () => {
            DrawerServiceInstance.open(AlectifyDrawer, {
              width: 860,
              title: "Multiple asset",
              closable: true,
              closeIcon: <CloseOutlined />,
              onClose: () => {
                DrawerServiceInstance.close(AlectifyDrawer);
                props.fetchTasks();
              },
              children: <PreventiveDocumentsAttachment details={task} />,
            });
          };
          const openProcedureCheckListDrawer = () => {
            DrawerServiceInstance.open(AlectifyDrawer, {
              width: 680,
              title: "Procedure",
              name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
              closable: true,
              closeIcon: <CloseOutlined />,
              onClose: () => {
                DrawerServiceInstance.close(AlectifyDrawer, {
                  name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
                });
                props.fetchTasks();
              },
              showFooter: true,
              destroyOnClose: true,
              readOnly: true,
              cancelText: AlectifyText.CLOSE,
              children: (
                <ProcedureDetail
                  procedure={task.procedure as any}
                  taskId={task.id}
                  isDisabled={
                    task.status === PM_STATUS.COMPLETED ||
                    task.status === PM_STATUS.SKIPPED
                  }
                />
              ),
            });
          };

          const handleUndoConfirm = async () => {
            try {
              ModalServiceInstance.close(AlectifyModal, {
                name: MODAL_NAMES.UNDO_WARNING_MODAL,
              });
              const resp = await undoTaskStatus(task.id);
              if (resp?.status) {
                message.success(
                  `${task?.workTitle} has changed the state from ${task?.status} to ${resp?.data?.status}`,
                );
                props.fetchTasks();
              }
            } catch (error) {
              message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
            }
          };
          const openWarningmodal = () => {
            ModalServiceInstance.open(AlectifyModal, {
              name: MODAL_NAMES.UNDO_WARNING_MODAL,
              title: AlectifyText.CONFIRMATION,
              footer: null,
              onCancel: () => {
                ModalServiceInstance.close(AlectifyModal, {
                  name: MODAL_NAMES.UNDO_WARNING_MODAL,
                });
              },
              children: (
                <ConfirmationModal
                  icon={WarningIcon}
                  message={MESSAGES.PM_EXTERNAL.REOPEN_WARNING}
                  note={MESSAGES.PM_EXTERNAL.REOPEN_NOTE}
                  onConfirm={handleUndoConfirm}
                  okText="Re open"
                  cancelText="Cancel"
                  onCancel={() =>
                    ModalServiceInstance.close(AlectifyModal, {
                      name: MODAL_NAMES.UNDO_WARNING_MODAL,
                    })
                  }
                />
              ),
            });
          };

          const dropdownItems = actionItems({
            currentUser,
            showMyWorkOrders,
            task,
            projectId: props.masterProjectId || "",
            subProjectId: props.subProjectId || "",
            navigate,
            myItemsColumns: props.myItemsColumns,
            onEdit: props.onEdit,
            updateStatus: props.updateStatus,
            openSubmitForReviewModal: props.openSubmitForReviewModal,
            onUpload: openAttachmentsDrawer,
            openProcedureCheckListDrawer,
            removeTask: props.removeTask,
            openSpareParts: props.openSpareParts,
            openWarningmodal: openWarningmodal,
            taskStatusStepIcon: taskStatusStepIcon,
            showDetailPageLink: true,
            goToPmDetail,
          });

          const renderDropdown: any =
            dropdownItems && dropdownItems.length > 0 ? dropdownItems : [];

          return (
            <>
              <Dropdown
                menu={{
                  items: renderDropdown,
                }}
                trigger={["click"]}
              >
                <EllipsisOutlined
                  rotate={90}
                  className="alectify-action-icon"
                />
              </Dropdown>
            </>
          );
        } else {
          <AlectifyButton
            type="primary"
            className="alectify-primary-btn"
            text="Select"
          />;
        }
      },
    },
  ];
  return React.useMemo(() => {
    if (pmExternal.activeWorkOrderCardSwitcher) {
      return simpleItemsColumn;
    }

    return !props.myItemsColumns
      ? columns.filter((cols) => cols.visible)
      : [...myItemsColumns, ...columns.filter((cols) => cols.visible)];
  }, [pmExternal.activeWorkOrderCardSwitcher, props.filter]);
};
export default useTasksColumn;
