export const ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  PROJECT: "/projects",
  CALENDAR: "/calendar",
  MY_ITEMS: "/my-work-orders",
  ALL_WORK_ORDERS: "/all-work-orders",
  SUB_PROJECT: "/sub-projects",
  RESET_PASSWORD: "/reset-password/:uidb64/:token",
  FORGET_PASSWORD: "/forgot-password",
  SUB_PROJECT_DETAIL: "/projects/:masterProjectId/sub-projects/:subProjectId",
  MASTER_PROJECT_DETAILS: "/projects",
  ANALYTICS: "analytics",
  USER_SETTING: "user-settings",
  SHARED_DOCS: "shared-docs",
  INVENTORY: "/spare-parts",
  COMPANY_WIDE: {
    COMPANY_WIDE: "company-wide",
    CALENDAR: "/company-wide/calendar",
    SPARE_PARTS: "/company-wide/spare-parts",
  },
  CONTACTS: "/contacts",
  TASK_DETAILS: "/task/:projectId/:subProjectId/:taskId",
  PM_DETAILS: "/pm/:projectId/:subProjectId/:pmId",
  PROCEDURES: "/procedures",
  TIMELINE: "/sub-projects/:subProjectId/timeline/:equipmentType/:equipmentId",
  ADMIN: "/projects-admin",
  IOT_DEVICES: "iot-devices",
  ALL_TIME_LINE: "/timeline",
  NOTIFICATIONS: "/notifications",
  ASSETS_DETAIL: "/assets-detail",
  CONTACT_US: "/contact-us",
  DOCUMENTS: "/documents",
  ICONS_GALLERY: "/icon-gallery",
  AUTO_CREATE_PROCEDURE_: "/auto-create-procedures",
  INCIDENT_REPORTS: "/incident-reports",
  DATA_ROUND: "/data-round",
  DATA_ROUND_DETAIL: "/data-round/:masterProjectId/project/:projectId",
  ASSET_DOCUMENTS: "asset-documents",
  SITE_DOCUMENTS: "site-documents",
  REPORTS: "audit",
};
