import { Breadcrumb, Col, Divider, Row, Typography } from "antd";
import AlectifyText from "static/texts.json";
import WorkOrderReports from "./work-order-reports/WorkOrderReports";
import "./Reports.scss";
import SelectMasterProject from "components/shared/master-project-select";
import SelectSubProject from "components/shared/sub-project-select/SelectSubProject";
import { useState } from "react";
import { IMasterProject } from "redux/components/master-project";
import { ISubProject } from "redux/components/sub-project";
import AlectifyButton from "components/shared/button";
import { getDownloadAssetsFile } from "services/assets/assets.service";
import { isEmpty } from "lodash";
import { downloadSparePartsAsCSV } from "services/spare-parts/spare-parts.service";

const Reports: React.FC<any> = () => {
  const [assetLoader, setAssetLoader] = useState<boolean>(false);
  const [sparePartsLoader, setSparePartsLoader] = useState<boolean>(false);

  const [selectedMasterProject, setSelectedMasterProject] =
    useState<IMasterProject | null>(null);
  const [selectedSubProject, setSelectedSubProject] =
    useState<ISubProject | null>(null);
  const [selectedMasterProjectForSp, setSelectedMasterProjectForSp] =
    useState<IMasterProject | null>(null);
  const [selectedSubProjectForSp, setSelectedSubProjectForSp] =
    useState<ISubProject | null>(null);
  const downloadAssetCSV = async () => {
    setAssetLoader(true);
    try {
      await getDownloadAssetsFile(selectedSubProject?.id || "");
      setAssetLoader(false);
    } catch (ex) {
      setAssetLoader(false);
      console.log(ex);
    }
  };

  const handleDownloadSparePartsCsv = async () => {
    try {
      setSparePartsLoader(true);
      const params = {
        projectId: selectedMasterProjectForSp?.id,
      };
      const response = await downloadSparePartsAsCSV(params);

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", "alectify_spare_parts.csv");

      document.body.appendChild(link);

      link.click();
      link?.parentNode?.removeChild(link);

      setSparePartsLoader(false);
    } catch (error) {
      console.error("Error downloading CSV:", error);
      setSparePartsLoader(false);
    }
  };

  return (
    <Row
      justify="start"
      align="top"
      gutter={[16, 16]}
      className="alectify-work-reports-container"
    >
      {/* Breadcrumb */}
      <Col span={24}>
        <Breadcrumb
          className=""
          items={[
            {
              title: "Home",
              href: `#`,
            },
            {
              title: AlectifyText.REPORTS,
            },
          ]}
        />
      </Col>

      {/* Work Orders Section */}
      <WorkOrderReports />
      <Divider className="mb-0" />
      <Col span={24}>
        <Typography.Title level={4}>Assets</Typography.Title>
      </Col>
      <Col span={24}>
        <Row
          justify={"start"}
          align={"middle"}
          gutter={16}
          className="alectify-wo-asset-report-container"
        >
          <Col span={4}>
            <Typography.Text>Assets List</Typography.Text>
          </Col>
          <Col span={4}>
            <SelectMasterProject
              onChange={(value: any) => setSelectedSubProject(null)}
              onSelect={(value: string, record: any) =>
                setSelectedMasterProject(record)
              }
              showLabel={false}
            />
          </Col>
          <Col span={4}>
            <SelectSubProject
              onSelect={(value: string, record: any) =>
                setSelectedSubProject(record)
              }
              masterProjectId={selectedMasterProject?.id || ""}
              showLabel={false}
            />
          </Col>
          <AlectifyButton
            text={"Download CSV"}
            type="primary"
            className="alectify-primary-btn"
            loading={assetLoader}
            disabled={
              isEmpty(selectedMasterProject) || isEmpty(selectedSubProject)
            }
            onClick={downloadAssetCSV}
          />
        </Row>
      </Col>
      <Divider className="mb-0" />
      <Col span={24}>
        <Typography.Title level={4}>Spare Parts</Typography.Title>
      </Col>
      <Col span={24}>
        <Row
          justify={"start"}
          align={"middle"}
          gutter={16}
          className="alectify-wo-asset-report-container"
        >
          <Col span={4}>
            <Typography.Text>Spare Parts List</Typography.Text>
          </Col>
          <Col span={4}>
            <SelectMasterProject
              onChange={(value: any) => setSelectedSubProjectForSp(null)}
              onSelect={(value: string, record: any) =>
                setSelectedMasterProjectForSp(record)
              }
              showLabel={false}
            />
          </Col>
          <Col span={4}>
            <SelectSubProject
              onSelect={(value: string, record: any) =>
                setSelectedSubProjectForSp(record)
              }
              masterProjectId={selectedMasterProjectForSp?.id || ""}
              showLabel={false}
            />
          </Col>
          <AlectifyButton
            text={"Download CSV"}
            type="primary"
            className="alectify-primary-btn"
            loading={sparePartsLoader}
            disabled={
              isEmpty(selectedMasterProjectForSp) ||
              isEmpty(selectedSubProjectForSp)
            }
            onClick={handleDownloadSparePartsCsv}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default Reports;
