import React, { ReactNode } from "react";
import { isEmpty } from "lodash";
import { ITask } from "redux/components/tasks";
import {
  Avatar,
  Col,
  Dropdown,
  Popover,
  Row,
  Space,
  Tooltip,
  message,
} from "antd";
import {
  displayDateTime,
  enumToTile,
  getFullUserName,
  truncateText,
} from "utils/helpers";
import {
  TaskStatusEnum,
  TaskPriorityEnum,
  PM_STATUS,
  PME_ACTION_REQUIRED_ENUM,
} from "enums";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import {
  EllipsisOutlined,
  CloseOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { actionItems } from "../TaskTable.rules";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import AvatarGroup from "components/shared/avatar-group";
import AlectifyText from "static/texts.json";
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import ViewImage from "components/shared/view-image/ViewImage";

import {
  ApprovedTag,
  ApproverTag,
  AssetPackageTag,
  AssetTag,
  AssigneeTag,
  CompletedTag,
  GenericTag,
  ScheduledTag,
  WaitingForApprovalTag,
  WaitingForReviewTag,
} from "components/shared/tags";
import DangerTag from "components/shared/tags/DangerTag";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";

import "assets/scss/global.scss";
import { getPMEActionRequired } from "components/shared/pm-external-table/PmExternal.helpers";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { useNavigate } from "react-router-dom";
import ClosedTag from "components/shared/tags/ClosedTag";
import {
  CalendarDateIcon,
  ChatBotIcon,
  UserIcon,
  WarningIcon,
} from "components/icons";
import { ITasksTableProps } from "../TasksTable.interface";
import { IProcedure } from "services/procedures/procedures.interface";
import StampCheckIcon from "components/icons/StampCheckIcon";
import { DONE_TASK_GREEN_COLOR } from "components/shared/phone-number/constants";
import { PM_STATUS_COMPONENTS } from "components/shared/pm-internal-table/effects/usePmInternalColumns";
import { IPmAssignees } from "redux/interfaces";
import { IPmExternal, PM_TYPES } from "redux/components/pm-external";
import PreventiveDocumentsAttachment from "components/shared/preventive-documents/PreventiveDocumentsAttachment";
import ProcedureDetail from "components/shared/procedure-detail";
import { undoTaskStatus } from "services/tasks/tasks.service";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import { MESSAGES } from "constants/messages";
import { ROUTES } from "routes/Routes.constants";
import { actions as NotificationActions } from "redux/components/notifications";
import "../TasksTable.scss";
import PmDetail from "components/shared/pm-detail";
import TextToLink from "components/shared/text-to-link";
import AlectifyTable from "components/shared/table";
import AlectifyEmpty from "components/shared/empty/AlectifyEmpty";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import AlectifyButton from "components/shared/button";

export interface ITaskTableColumnHookProps extends ITasksTableProps {
  onEdit: (_: any, editing: boolean, editType: string, record: ITask) => void;
  filter: string[];
  optionsPersisted?: IPagination | null;
  showSelection?: boolean;
  fetchTasks: () => void;
  updateStatus: (pmId: IPmExternal | ITask, status: string) => void;
  openSubmitForReviewModal: (task: ITask) => void;
  removeTask: (id: string) => void;
  openSpareParts?: (task: IPmExternal | ITask) => void;
  onSelectWorkOrder?: (task: ITask) => void;
}

export const TASK_STATUS_COMPONENTS = {
  [TaskStatusEnum.PENDING]: <ScheduledTag />,
  [TaskStatusEnum.APPROVED]: <ApprovedTag />,
  [TaskStatusEnum.SCHEDULED]: <ScheduledTag />,
  [TaskStatusEnum.COMPLETED]: <ClosedTag />,
  [TaskStatusEnum.REVIEW_DECLINED]: <WaitingForReviewTag />,
  [TaskStatusEnum.WAITING_FOR_REVIEW]: <WaitingForReviewTag />,
  [TaskStatusEnum.WAITING_FOR_APPROVAL]: <WaitingForApprovalTag />,
};
export const taskStatusStepIcon = (
  currentStatus: string,
  statusToMove: string,
): ReactNode => {
  if (
    currentStatus === PM_STATUS.PENDING &&
    statusToMove === PM_STATUS.WAITING_FOR_REVIEW
  ) {
    return AlectifyText.SUBMIT_REVIEW;
  } else if (
    currentStatus === PM_STATUS.INPROGRESS &&
    statusToMove === PM_STATUS.WAITING_FOR_REVIEW
  ) {
    return AlectifyText.SUBMIT_REVIEW;
  } else if (
    currentStatus === PM_STATUS.PENDING &&
    statusToMove === PM_STATUS.INPROGRESS
  ) {
    return AlectifyText.IN_PROGRESS;
  } else if (
    currentStatus === PM_STATUS.WAITING_FOR_REVIEW &&
    statusToMove === PM_STATUS.COMPLETED
  ) {
    return AlectifyText.REVIEW_COMPLETE;
  }
  return <></>;
};
export const TASK_PROIRITY_COMPONENTS = {
  [TaskPriorityEnum.NORMAL]: <CompletedTag text={AlectifyText.NORMAL} />,
  [TaskPriorityEnum.CRITICAL]: <DangerTag text={AlectifyText.CRITICAL} />,
};

export const PM_DETAIL_PRIORITY_COMPONENTS = {
  [TaskPriorityEnum.NORMAL]: null,
  [TaskPriorityEnum.CRITICAL]: <DangerTag text={AlectifyText.CRITICAL} />,
};

export const ACTION_REQUIRED_COMPONENTS = (
  action: PME_ACTION_REQUIRED_ENUM | null,
) => {
  switch (action) {
    case PME_ACTION_REQUIRED_ENUM.ASSIGNEE:
      return <AssigneeTag text={action} />;
    case PME_ACTION_REQUIRED_ENUM.APPROVERS:
      return <ApproverTag />;
    default:
      return <></>;
  }
};

export const getTaskStatusIcon = (procedure: IProcedure): JSX.Element => {
  const isAllChecked =
    procedure.procedureStepCheckedTotalCount ===
    procedure.procedureStepTotalCount;
  return isAllChecked ? (
    <StampCheckIcon fill={DONE_TASK_GREEN_COLOR} />
  ) : (
    <StampCheckIcon />
  );
};

const useSimpleColumns = (props: ITaskTableColumnHookProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTaskType = props.type === PM_TYPES.TASK;
  const currentUser = useSelector((state: IRootState) => state.auth.user);
  const { showMyWorkOrders } = useSelector((state: IRootState) => state.common);
  const { projectTeamMembers } = useSelector(({ users }: IRootState) => users);

  const goToPmDetail = (task: ITask) => {
    const pmId = task.id;
    const masterProjectId = task?.project?.id;
    const subProjectId = task?.subProject?.id;
    const url = `${ROUTES.MY_ITEMS}/pm/${masterProjectId}/${subProjectId}/${pmId}?pmType=${task.pmType}`;
    dispatch(NotificationActions.toggleNotificationDropdown(false));
    navigate(url);
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: [DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER],
    });
  };

  const onCell = (record: any, rowIndex: number) => ({
    style: {
      cursor: "pointer",
    },

    onClick: () => {
      goToPmDetail(record);
    },
  });

  return React.useMemo<ColumnProps<any>[] | ColumnGroupType<any>[] | any[]>(
    () =>
      [
        {
          title: AlectifyText.DUEDATE,
          key: "dueDate",
          dataIndex: "dueDate",
          visible: true,
          width: 100,
          onCell,
          render: (dueDate: Date, record: ITask) => (
            <div className="d-flex justify-content-space-between">
              <Space direction="vertical" size={15}>
                <div className="creator-container">
                  <CalendarDateIcon />
                  <span className="creator-name-date">
                    {`${displayDateTime(dueDate, false, false)}`}
                  </span>
                </div>
              </Space>
            </div>
          ),
        },

        {
          title: AlectifyText.TITLE,
          dataIndex: "workTitle",
          width: 200,
          visible: true,
          ellipsis: true,
          searchable: true,
          onCell,
          render: (value: string, task: ITask) => {
            const openProcedureCheckListDrawer = () => {
              DrawerServiceInstance.open(AlectifyDrawer, {
                width: 680,
                title: "Procedures",
                name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
                closable: true,
                closeIcon: <CloseOutlined />,
                onClose: () => {
                  DrawerServiceInstance.close(AlectifyDrawer, {
                    name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
                  });
                  props.fetchTasks();
                },
                showFooter: true,
                destroyOnClose: true,
                readOnly: true,
                cancelText: AlectifyText.CLOSE,
                children: (
                  <ProcedureDetail
                    procedure={task.procedure as any}
                    taskId={task.id}
                    isDisabled={
                      task.status === PM_STATUS.COMPLETED ||
                      task.status === PM_STATUS.SKIPPED
                    }
                  />
                ),
              });
            };

            const content = (
              <div>
                {task.summary ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: (task.summary as any) || "",
                    }}
                    className="ai-summary-wo-content"
                  />
                ) : (
                  <div className="mt-10">
                    <AlectifyEmpty
                      description="No Summary Found"
                      width="100"
                      height="90"
                      className="dark-grey"
                    />
                  </div>
                )}
              </div>
            );

            return (
              <Row gutter={16} wrap={false}>
                <div className="row-width">
                  <Col
                    span={task?.procedure ? 19 : 24}
                    className="title-container-wo"
                  >
                    <Popover
                      content={content}
                      trigger="hover"
                      placement="rightBottom"
                      title={
                        <div className="d-flex align-items-center justify-content-space-between">
                          <div className="ml-5 font-size-17">AI Summary</div>
                          <div className="mr-10">
                            <ChatBotIcon />
                          </div>
                        </div>
                      }
                      overlayClassName="ai-summary-work-order-popover ai-table-summary"
                      arrow={true}
                    >
                      <span
                        className="text-ellipsis"
                        onClick={(e) => {
                          e.stopPropagation();
                          DrawerServiceInstance.open(AlectifyDrawer, {
                            width: 480,
                            title: truncateText(task.workTitle, 40),
                            name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                            closable: true,
                            className: "bg-grey",
                            closeIcon: <CloseOutlined />,
                            onClose: () =>
                              DrawerServiceInstance.close(AlectifyDrawer, {
                                name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                              }),
                            children: (
                              <PmDetail
                                pmExternalRecord={task as any}
                                pmId={task.id}
                                pmType={
                                  props.type === "ALL"
                                    ? task.pmType
                                    : props.type
                                }
                              />
                            ),
                          });
                        }}
                      >
                        <span
                          className={`${
                            value ? " text-link-title" : "no-text-link-title"
                          }`}
                        >
                          {value || "(No title)"}
                        </span>
                      </span>
                    </Popover>
                  </Col>

                  {/* <Col span={2}>
                        {task?.priority === TaskPriorityEnum.CRITICAL && (
                          <Tooltip title={TaskPriorityEnum.CRITICAL}>
                            <WarningIcon height={12} width={17} />
                          </Tooltip>
                        )}
                      </Col> */}
                  <Col span={5} className="simple-col-notifications">
                    {!isEmpty(task.procedure) && (
                      <Tooltip
                        title={`${AlectifyText.PROCEDURE_CHECKLIST} ${task.procedure?.procedureStepCheckedTotalCount}/${task.procedure?.procedureStepTotalCount}`}
                      >
                        <div
                          className="procedure-col"
                          onClick={(e) => {
                            e.stopPropagation();
                            openProcedureCheckListDrawer();
                          }}
                        >
                          <span className="cursor-pointer d-flex align-items-center alectify-task-table-collaboration-count-text">
                            {!isEmpty(task.procedure) &&
                              getTaskStatusIcon(task.procedure)}
                          </span>
                          <span className="count-procedure">
                            {task.procedure?.procedureStepCheckedTotalCount}/
                            {task.procedure?.procedureStepTotalCount}
                          </span>
                        </div>
                      </Tooltip>
                    )}
                  </Col>
                </div>
              </Row>
            );
          },
        },

        {
          title: AlectifyText.ASSET,
          key: "asset_type",
          dataIndex: "asset_type",
          width: 150,
          visible: true,
          onCell,
          render: (_: any, record: ITask) => {
            const assetCount = record.assets.length || 0;
            const areaCount = record.areas.length || 0;
            const total = assetCount + areaCount || 0;
            const displayText =
              total > 1 ? (
                <strong>{`Multiple Assets [${total}]`}</strong>
              ) : (
                (record.assets[0] as any)?.asset?.name ||
                (record.areas[0] as any)?.area?.name ||
                "No Asset"
              );
            const isGeneric = record?.isGeneric;
            return (
              <Space direction="vertical" size={15}>
                <Tooltip
                  title={
                    (!isEmpty(record.assets) || !isEmpty(record.areas)) &&
                    "Assets"
                  }
                >
                  {displayText === "No Asset" ? (
                    displayText
                  ) : (
                    <TextToLink
                      className="text-to-link-options"
                      text={displayText}
                      underline={!isGeneric}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!isGeneric) {
                          const mergedRecords =
                            [...record.assets, ...record.areas].length > 1;

                          if (mergedRecords) {
                            // Open the modal with the list of assets and areas
                            ModalServiceInstance.open(AlectifyModal, {
                              name: MODAL_NAMES.ASSET_NAME_MODAL,
                              title: `Asset list of (${record.workTitle})`,
                              footer: null,
                              width: 800,
                              children: (
                                <AlectifyTable
                                  onDataFetch={() => {}}
                                  dataSource={[
                                    ...record.assets.map((asset: any) => {
                                      return { ...asset.asset, type: "Tag" };
                                    }),
                                    ...record.areas.map((ar: any) => {
                                      return {
                                        ...ar.area,
                                        type: "PackageRoom",
                                      };
                                    }),
                                  ]}
                                  total={
                                    [...record.assets, ...record.areas].length
                                  }
                                  showPagination={false}
                                  columns={[
                                    {
                                      dataIndex: "name",
                                      width: "30%",
                                      ellipsis: true,
                                      title: "Name",
                                      render: (value, assetRecord) => {
                                        const goToAssetDetails = () => {
                                          let url: string;
                                          if (
                                            assetRecord.type === "PackageRoom"
                                          ) {
                                            url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset-package/${assetRecord.id}?page=timeline`;
                                          } else {
                                            url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset/${assetRecord.id}?page=timeline`;
                                          }
                                          window.open(url, "_blank");
                                        };
                                        return (
                                          <TextToLink
                                            text={
                                              <Space>
                                                <span>{value}</span>
                                                <ExportOutlined />
                                              </Space>
                                            }
                                            onClick={goToAssetDetails}
                                          />
                                        );
                                      },
                                    },

                                    {
                                      title: AlectifyText.ASSET_TYPE,
                                      key: "asset_type",
                                      width: "20%",
                                      render: (value, assetRecord) => {
                                        return (
                                          <>
                                            {assetRecord.type === "Tag" ? (
                                              <AssetTag />
                                            ) : assetRecord.type ===
                                              "PackageRoom" ? (
                                              <AssetPackageTag />
                                            ) : (
                                              <GenericTag />
                                            )}
                                          </>
                                        );
                                      },
                                    },

                                    {
                                      dataIndex: "description",
                                      title: "description",
                                      width: "50%",
                                      ellipsis: true,
                                      render: (description, assetRecord) => {
                                        return <div>{description || "-"}</div>;
                                      },
                                    },
                                  ]}
                                />
                              ),
                              onCancel: () =>
                                ModalServiceInstance.close(AlectifyModal, {
                                  name: MODAL_NAMES.ASSET_NAME_MODAL,
                                }),
                            });
                          } else {
                            // Redirect directly to asset/area detail page
                            const singleAsset: any =
                              record.assets.length === 1
                                ? record.assets[0]
                                : null;
                            const singleArea: any =
                              record.areas.length === 1
                                ? record.areas[0]
                                : null;

                            if (singleAsset) {
                              const url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset/${singleAsset.asset.id}?page=timeline`;
                              window.open(url, "_blank");
                            } else if (singleArea) {
                              const url = `${ROUTES.SUB_PROJECT}/${record.subProject.id}/timeline/asset-package/${singleArea.area.id}?page=timeline`;
                              window.open(url, "_blank");
                            }
                          }
                        }
                      }}
                    />
                  )}
                </Tooltip>
              </Space>
            );
          },
        },
        {
          title: AlectifyText.MAINTENANCE_TYPE,
          key: "taskCategory",
          dataIndex: "taskCategory",
          visible: !isTaskType,
          ellipsis: true,
          width: 160,
          onCell,
          render: (target: any, record: ITask) => (
            <>
              {`${
                record.pmType === "TASK"
                  ? "Task"
                  : (record.taskCategory && enumToTile(record.taskCategory)) ||
                    "-"
              }`}
            </>
          ),
        },

        {
          title: AlectifyText.STATUS,
          dataIndex: "status",
          key: "status",
          width: 180,
          visible: true,
          onCell,
          render: (value: Date, task: ITask) => {
            return <>{PM_STATUS_COMPONENTS[task.status]}</>;
          },
        },

        {
          title: AlectifyText.ACTION_REQUIRED_BY,
          dataIndex: "assignees",
          key: "assignees",
          width: 130,
          onCell,
          filters: projectTeamMembers?.data.map((user) => ({
            text: getFullUserName(user),
            value: user.id,
          })),
          visible:
            props.filter.includes(PM_STATUS.PENDING) ||
            props.filter.includes(PM_STATUS.WAITING_FOR_REVIEW),
          render: (assignees: IPmAssignees[], record: IPmExternal) => {
            const actionRequired = getPMEActionRequired(record);
            if (!isEmpty(assignees) || !isEmpty(record.approvers)) {
              return (
                <Space direction="vertical" size={5}>
                  <AvatarGroup
                    maxCount={2}
                    showImage
                    users={
                      actionRequired === PME_ACTION_REQUIRED_ENUM.ASSIGNEE
                        ? assignees.map(({ user }) => user)
                        : record.approvers?.map(({ user }) => user)
                    }
                  />
                </Space>
              );
            }
            return <></>;
          },
        },

        {
          title: AlectifyText.ASSET,
          key: "asset_name",
          dataIndex: "asset_name",
          width: 200,
          visible: false,
          onCell,
          ellipsis: true,
          render: (_: any, record: ITask) => {
            const isGeneric = record?.isGeneric;
            return (
              <div
                className="asset-col text-ellipsis"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isGeneric) {
                    /*  DrawerServiceInstance.open(AlectifyDrawer, {
                      width: record.asset?.name ? 420 : 900,
                      title: truncate(record.asset?.name || record.area?.name, {
                        length: 30,
                        omission: "...",
                      }),
                      name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                      closable: true,
                      closeIcon: <CloseOutlined />,
                      onClose: () =>
                        DrawerServiceInstance.close(AlectifyDrawer, {
                          name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                        }),
                      children: !isEmpty(record.asset) ? (
                        <AssetDetail
                          taskRecord={record}
                          subProjectId={record?.subProject?.id}
                          assetId={record.asset?.id}
                          navigate={navigate}
                          isDetailButton
                          isUploadbutton
                        />
                      ) : (
                        <PackageDetailAssets
                          taskRecord={record}
                          subProjectId={record?.subProject?.id}
                          assetPackageId={record.area?.id || ""}
                          navigate={navigate}
                          isDetailsbutton
                          isUploadbutton
                        />
                      ),
                    }); */
                  }
                }}
              >
                {/* {record.asset?.name ? (
                  <>
                    <Tooltip title={record.asset?.name}>
                      <AssetTag showSubAssetName={false} />
                      <span className="active-text">
                        {truncate(record.asset?.name)}
                      </span>
                    </Tooltip>
                  </>
                ) : record.area?.name ? (
                  <>
                    <AssetPackageTag showParentAssetName={false} />
                    <Tooltip title={record.area?.name}>
                      <span className="active-text">
                        {truncate(record.area?.name)}
                      </span>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <GenericTag showGenericName={false} />
                    <span className="no-text-link-title">No Asset</span>
                  </>
                )} */}
              </div>
            );
          },
        },

        {
          title: "Created",
          visible: true,
          key: "createdAt",
          dataIndex: "createdAt",
          width: 180,
          render: (created: any, record: ITask) => {
            return (
              <>
                {isEmpty(record?.createdBy?.image_url) ? (
                  <UserIcon />
                ) : (
                  <Space>
                    <Tooltip
                      title={`${getFullUserName(record?.createdBy as any)}`}
                    >
                      <Avatar
                        src={record?.createdBy?.image_url}
                        className="cursor-pointer"
                        onClick={() =>
                          ModalServiceInstance.open(AlectifyModal, {
                            name: "alectify-task-image-viewer",
                            title: "Preview",
                            footer: null,
                            onCancel: () => {
                              ModalServiceInstance.close(AlectifyModal, {
                                name: "alectify-task-image-viewer",
                              });
                            },
                            children: (
                              <ViewImage
                                imageUrl={
                                  record?.createdBy?.image_url ||
                                  NO_IMAGE_AVATAR
                                }
                                isDownloadable={
                                  !isEmpty(record?.createdBy?.image_url)
                                }
                              />
                            ),
                          })
                        }
                      />
                    </Tooltip>
                    <span className="creator-name-date">
                      {`${displayDateTime(record?.createdAt, true, false)}`}
                    </span>
                  </Space>
                )}
              </>
            );
          },
        },

        // {
        //   title: AlectifyText.NOTIFICATIONS,
        //   width: 120,
        //   dataIndex: "messages",
        //   visible: false,
        //   searchable: true,
        //   onCell,
        //   render: (_: any, record: any) => {
        //     const subProject = record.subProject || null;

        //     const openAttachmentsDrawer = () => {
        //       DrawerServiceInstance.open(AlectifyDrawer, {
        //         width: 860,
        //         title: AlectifyText.ATTACHMENTS,
        //         name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
        //         closable: true,
        //         closeIcon: <CloseOutlined />,
        //         onClose: () => {
        //           DrawerServiceInstance.close(AlectifyDrawer, {
        //             name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
        //           });
        //           props.fetchTasks(props.optionsPersisted as any);
        //         },
        //         children: <PreventiveDocumentsAttachment details={record} />,
        //       });
        //     };

        //     const openCommentsDrawer = () => {
        //       DrawerServiceInstance.open(AlectifyDrawer, {
        //         width: 600,
        //         title: AlectifyText.MESSAGING_CENTER,
        //         name: DRAWER_CONSTANTS.DRAWER_COMMENTS,
        //         closable: true,
        //         closeIcon: <CloseOutlined />,
        //         onClose: () => {
        //           DrawerServiceInstance.close(AlectifyDrawer, {
        //             name: DRAWER_CONSTANTS.DRAWER_COMMENTS,
        //           });
        //           props.fetchTasks(props.optionsPersisted as any);
        //         },
        //         children: (
        //           <Comments
        //             details={record && record}
        //             referenceId={record.id}
        //             subProjectId={subProject?.id}
        //             reference_type={CommentReferenceEnum.PM_EXTERNAL}
        //           />
        //         ),
        //         className: "alectify-drawer-task-comments",
        //       });
        //     };

        //     const openProcedureCheckListDrawer = () => {
        //       DrawerServiceInstance.open(AlectifyDrawer, {
        //         width: 680,
        //         title: "Procedures",
        //         name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
        //         closable: true,
        //         closeIcon: <CloseOutlined />,
        //         onClose: () => {
        //           DrawerServiceInstance.close(AlectifyDrawer, {
        //             name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
        //           });
        //           props.fetchTasks(props.optionsPersisted as any);
        //         },
        //         showFooter: true,
        //         destroyOnClose: true,
        //         readOnly: true,
        //         cancelText: AlectifyText.CLOSE,
        //         children: (
        //           <ProcedureDetail
        //             procedure={record.procedure as any}
        //             taskId={record.id}
        //             isDisabled={
        //               record.status === PM_STATUS.COMPLETED ||
        //               record.status === PM_STATUS.SKIPPED
        //             }
        //           />
        //         ),
        //       });
        //     };

        //     return (
        //       <Row
        //         justify={"space-between"}
        //         gutter={12}
        //         className="simple-col-notifications"
        //         wrap={false}
        //       >
        //         <Col
        //           span={8}
        //           onClick={(e) => {
        //             e.stopPropagation();
        //             openCommentsDrawer();
        //           }}
        //         >
        //           <Tooltip
        //             placement="topLeft"
        //             title={AlectifyText.MESSAGING_CENTER}
        //           >
        //             <div className="d-flex align-items-center ">
        //               <MessageOutlined
        //                 className="icon-font-size-18"
        //                 style={{
        //                   color: "#0954f1",
        //                   marginRight: "3px",
        //                 }}
        //               />
        //               <span className="alectify-task-table-collaboration-count-text">
        //                 {record?.comments}
        //               </span>
        //             </div>
        //           </Tooltip>
        //         </Col>
        //         <Col
        //           span={8}
        //           onClick={(e) => {
        //             e.stopPropagation();
        //             openAttachmentsDrawer();
        //           }}
        //         >
        //           <Tooltip placement="topLeft" title={AlectifyText.ATTACHMENTS}>
        //             <div className="d-flex align-items-center">
        //               <PaperClipOutlined
        //                 className="icon-font-size-18"
        //                 style={{
        //                   color: "#0954f1",
        //                 }}
        //               />
        //               <span className="alectify-task-table-collaboration-count-text">
        //                 {record?.documents}
        //               </span>
        //             </div>
        //           </Tooltip>
        //         </Col>

        //         <Col span={8}>
        //           {!isEmpty(record.procedure) && (
        //             <Tooltip
        //               title={`${AlectifyText.PROCEDURE_CHECKLIST} ${record.procedure?.procedureStepCheckedTotalCount}/${record.procedure?.procedureStepTotalCount}`}
        //             >
        //               <div className="procedure-col">
        //                 <span
        //                   onClick={(e) => {
        //                     e.stopPropagation();
        //                     openProcedureCheckListDrawer();
        //                   }}
        //                   className="cursor-pointer d-flex align-items-center alectify-task-table-collaboration-count-text"
        //                 >
        //                   {!isEmpty(record.procedure) &&
        //                     getTaskStatusIcon(record.procedure)}
        //                 </span>
        //                 <span className="count-procedure">
        //                   {record.procedure?.procedureStepCheckedTotalCount}/
        //                   {record.procedure?.procedureStepTotalCount}
        //                 </span>
        //               </div>
        //             </Tooltip>
        //           )}
        //         </Col>
        //       </Row>
        //     );
        //   },
        // },

        // {
        //   title: AlectifyText.ASSET_TYPE,
        //   key: "asset_type",
        //   width: 130,
        //   visible: true,
        //   onCell,
        //   render: (value: Date, record: ITask) => {
        //     return (
        //       <>
        //         {record.asset?.name ? (
        //           <AssetTag />
        //         ) : record.area?.name ? (
        //           <AssetPackageTag />
        //         ) : (
        //           <GenericTag />
        //         )}
        //       </>
        //     );
        //   },
        // },

        // {
        //   title: AlectifyText.ATTACHMENTS,
        //   width: 100,
        //   onCell,
        //   dataIndex: "attachment",
        //   visible: true,
        //   searchable: true,
        //   render: (_: any, record: any) => {
        //     const openAttachmentsDrawer = () => {
        //       DrawerServiceInstance.open(AlectifyDrawer, {
        //         width: 860,
        //         title: AlectifyText.ATTACHMENTS,
        //         name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
        //         closable: true,
        //         closeIcon: <CloseOutlined />,
        //         onClose: () => {
        //           DrawerServiceInstance.close(AlectifyDrawer, {
        //             name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
        //           });
        //           props.fetchTasks(props.optionsPersisted as any);
        //         },
        //         children: <PreventiveDocumentsAttachment details={record} />,
        //       });
        //     };

        //     return (
        //       <Space
        //         direction="vertical"
        //         className="alectify-task-table-collaboration-column"
        //       >
        //         <Tooltip placement="topLeft" title={AlectifyText.ATTACHMENTS}>
        //           <Badge
        //             offset={[2, 0]}
        //             overflowCount={9}
        //             className="cursor-pointer"
        //             size="small"
        //           >
        //             <div
        //               className="d-flex align-items-center"
        //               onClick={(e) => {
        //                 e.stopPropagation();
        //                 openAttachmentsDrawer();
        //               }}
        //             >
        //               <PaperClipOutlined
        //                 className="icon-font-size-18"
        //                 style={{
        //                   color: "#0954f1",
        //                 }}
        //               />
        //               <span
        //                 className="alectify-task-table-collaboration-count-text"
        //                 onClick={openAttachmentsDrawer}
        //               >
        //                 {record?.documents}
        //               </span>
        //             </div>
        //           </Badge>
        //         </Tooltip>
        //       </Space>
        //     );
        //   },
        // },

        // {
        //   title: AlectifyText.AI_SUMMARY,
        //   dataIndex: "summary",
        //   key: "summary",
        //   width: 130,
        //   visible: false,
        //   render: (_: any, record: ITask) => {
        //     return (
        //       <AlectifyAiSummaryButton
        //         content={record.summary}
        //         name={record.workTitle}
        //         key={record.id}
        //       />
        //     );
        //   },
        // },

        // {
        //   title: AlectifyText.WORK_ID,
        //   dataIndex: "workId",
        //   width: 140,
        //   visible: false,
        //   ellipsis: true,
        //   onCell,
        //   render: (value: string, task: ITask) => (
        //     <Tooltip title={value}>
        //       <div className="work-id-container">
        //         <span className="text-ellipsis">{value || "-"}</span>
        //       </div>
        //     </Tooltip>
        //   ),
        // },

        // {
        //   title: AlectifyText.CREATED_BY,
        //   key: "created",
        //   dataIndex: "createdAt",
        //   visible: false,
        //   sorter: true,
        //   sortingColumn: "createdAt",
        //   ellipsis: true,
        //   width: 100,
        //   onCell,
        //   render: (target: any, record: ITask) => (
        //     <div className="creator-container">
        //       {isEmpty(record?.createdBy?.image_url) ? (
        //         <UserIcon />
        //       ) : (
        //         <Avatar src={record?.createdBy?.image_url} size="small" />
        //       )}
        //     </div>
        //   ),
        // },

        {
          width: 60,
          title: AlectifyText.ACTIONS,
          align: "center",
          fixed: "right",
          dataIndex: "",
          visible: true,
          render: (_: any, task: ITask) => {
            if (!props.showSelection) {
              const openAttachmentsDrawer = () => {
                DrawerServiceInstance.open(AlectifyDrawer, {
                  width: 860,
                  title: "Multiple asset change",
                  closable: true,
                  closeIcon: <CloseOutlined />,
                  onClose: () => {
                    DrawerServiceInstance.close(AlectifyDrawer);
                    props.fetchTasks();
                  },
                  children: <PreventiveDocumentsAttachment details={task} />,
                });
              };
              const openProcedureCheckListDrawer = () => {
                DrawerServiceInstance.open(AlectifyDrawer, {
                  width: 680,
                  title: "Procedure",
                  name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
                  closable: true,
                  closeIcon: <CloseOutlined />,
                  onClose: () => {
                    DrawerServiceInstance.close(AlectifyDrawer, {
                      name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
                    });
                    props.fetchTasks();
                  },
                  showFooter: true,
                  destroyOnClose: true,
                  readOnly: true,
                  cancelText: AlectifyText.CLOSE,
                  children: (
                    <ProcedureDetail
                      procedure={task.procedure as any}
                      taskId={task.id}
                      isDisabled={
                        task.status === PM_STATUS.COMPLETED ||
                        task.status === PM_STATUS.SKIPPED
                      }
                    />
                  ),
                });
              };

              const handleUndoConfirm = async () => {
                try {
                  ModalServiceInstance.close(AlectifyModal, {
                    name: MODAL_NAMES.UNDO_WARNING_MODAL,
                  });
                  const resp = await undoTaskStatus(task.id);
                  if (resp?.status) {
                    message.success(
                      `${task?.workTitle} has changed the state from ${task?.status} to ${resp?.data?.status}`,
                    );
                    props.fetchTasks();
                  }
                } catch (error) {
                  message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
                }
              };
              const openWarningmodal = () => {
                ModalServiceInstance.open(AlectifyModal, {
                  name: MODAL_NAMES.UNDO_WARNING_MODAL,
                  title: AlectifyText.CONFIRMATION,
                  footer: null,
                  onCancel: () => {
                    ModalServiceInstance.close(AlectifyModal, {
                      name: MODAL_NAMES.UNDO_WARNING_MODAL,
                    });
                  },
                  children: (
                    <ConfirmationModal
                      icon={WarningIcon}
                      message={MESSAGES.PM_EXTERNAL.REOPEN_WARNING}
                      note={MESSAGES.PM_EXTERNAL.REOPEN_NOTE}
                      onConfirm={handleUndoConfirm}
                      okText="Re open"
                      cancelText="Cancel"
                      onCancel={() =>
                        ModalServiceInstance.close(AlectifyModal, {
                          name: MODAL_NAMES.UNDO_WARNING_MODAL,
                        })
                      }
                    />
                  ),
                });
              };

              const dropdownItems = actionItems({
                currentUser,
                showMyWorkOrders,
                task,
                projectId: props.masterProjectId || "",
                subProjectId: props.subProjectId || "",
                navigate,
                myItemsColumns: props.myItemsColumns,
                onEdit: props.onEdit,
                updateStatus: props.updateStatus,
                openSubmitForReviewModal: props.openSubmitForReviewModal,
                onUpload: openAttachmentsDrawer,
                openProcedureCheckListDrawer,
                removeTask: props.removeTask,
                openSpareParts: props.openSpareParts,
                openWarningmodal: openWarningmodal,
                taskStatusStepIcon: taskStatusStepIcon,
                showDetailPageLink: true,
                goToPmDetail,
              });

              const renderDropdown: any =
                dropdownItems && dropdownItems.length > 0 ? dropdownItems : [];

              return (
                <>
                  <Dropdown
                    menu={{
                      items: renderDropdown,
                    }}
                    trigger={["click"]}
                  >
                    <EllipsisOutlined
                      rotate={90}
                      className="alectify-action-icon"
                    />
                  </Dropdown>
                </>
              );
            } else {
              return (
                <AlectifyButton
                  type="primary"
                  className="alectify-primary-btn"
                  text="Select"
                  onClick={() =>
                    props.onSelectWorkOrder && props.onSelectWorkOrder(task)
                  }
                />
              );
            }
          },
        },
      ].filter((column) => column.visible),
    [isTaskType, props.myItemsColumns],
  );
};
export default useSimpleColumns;
